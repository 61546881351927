import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Axios from 'axios';
export default class Header extends Component<any, any> {

  constructor(props)
  {
      super(props); 
      this.state  = {
          url : process.env.REACT_APP_API_URL,
          redirect : null
      }
  }

  componentDidMount()
  {
    let isPaid = localStorage.getItem('isPaid');
    if(isPaid === 'yes')
    {
      let th = this; 
      Axios({
        method : 'GET', 
        data : {}, 
        url  : this.state.url+'users/payment-status', 
        headers:{
          Authorization : 'Bearer '+localStorage.getItem('token'),
          Accept : 'application/json'
        }
      })
      .then((res)=>{
        let payment_status = res.data.payment_status; 
        if(payment_status === 'PAYMENT_PENDING')
        {
          localStorage.clear();
          th.setState({
            redirect : '/logout'
          });
          return false; 
        }
      })
      .catch((err)=>{
        if(err.response.data.message === 'Unauthenticated.')
        {
          th.setState({
            redirect : '/logout'
          });
        }
      });
  }
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    
    let photo = localStorage.getItem('photoURL');
    return (
      <React.Fragment>
        <Helmet>
          <title>Practiceus Dashboard</title>
          <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
          <link rel="stylesheet" href="/assets/css/dashboard.css?v=1.1.10" />
        </Helmet>
          <header>
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light">
                <Link to="/" className="navbar-brand logo" >
                  <img src="/practiceus-assets/image/practiceus-logo2.png" alt="" className="img-fluid" />
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedcontant" aria-controls="navbarSupportedcontant" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedcontant">
                  <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                  <Link to={'/how-it-works'} className="dropdown-item" >How It works</Link>
                    </li>

                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#?" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Test</a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link to="/chapters"><i><img src="/dashboard-assets/img/Chapter-test.png" alt="" /></i>Chapter Test</Link>
                        <Link to="/subjects"><i><img src="/dashboard-assets/img/Subject-test.png" alt="" /></i>Subject Test</Link>
                        <Link to="/full-test"><i><img src="/dashboard-assets/img/Full-test.png" alt="" /></i>Full Test</Link>
                        <Link to="/previous-year-questions"><i><img src="/dashboard-assets/img/Full-test.png" alt="" /></i>Previous Years</Link>
                      </div>
                    </li>
                    <li className="nav-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#?" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src={`${photo}`} alt="" style={{ width: '40px', height: 'auto' }} className="img-fluid" />
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link to={'/update-profile'} className="dropdown-item" >Update Profile</Link>
                        <Link to={'/change-password'} className="dropdown-item" >Change Password</Link>
                        
                        <Link to="/logout">Logout</Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </header>


</React.Fragment>
    )
  }
}
