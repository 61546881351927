import React, { Component } from 'react';
import {connect} from 'react-redux';
import Axios from 'axios';
import Loading from '../template/Loading'; 
import { Redirect, Link } from 'react-router-dom';
import Helmet from 'react-helmet';
 class Subjecttest extends Component<any,any> {

    constructor(props)
    {
        super(props); 
        this.state  = {
            data : {}, 
            url : process.env.REACT_APP_API_URL,
            user : this.props.user,
            redirect : null,
            isLoading : true 
        }
    }

    componentDidMount()
    {
      window.scrollTo(0, 0);
      this.getAPIData();
    }

    getAPIData()
    {
      let th = this; 
      if(this.props.data.subjectTestAPI !== undefined)
      {
        this.setState({
          data : {
            apiData: this.props.data.subjectTestAPI
          },
          isLoading : false
        });
      }
      else
      {
        Axios({
          method : 'GET', 
          data : {}, 
          url  : this.state.url+'dashboard/subject-test-list?page=1', 
          headers:{
            Authorization : 'Bearer '+this.state.user.token,
            Accept : 'application/json'
          }
        })
        .then((res)=>{

          let payment_status = res.data.payment_status; 
          if(payment_status === 'PAYMENT_PENDING')
          {
            th.setState({
              redirect : '/logout'
            });
            return false; 
          }
          
          th.setState({
            isLoading:false, 
            data : {
              apiData : res.data
            }
          });
          th.props.updateData({ subjectTestAPI: res.data });
        })
        .catch((err)=>{
          if(err.response.data.message === 'Unauthenticated.')
          {
            th.setState({
              redirect : '/logout'
            });
          }
        })
      }
    }

    
    
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
          }
            if (this.state.isLoading === true) return <Loading/>
            let data = this.state.data.apiData.data; 
            //console.log(data);
        return (
           <React.Fragment>
               <Helmet>
                   <title>Subject Test - Quiz App</title>
               </Helmet>
          <section>
  <div className="container wraper">
    <div className="row">
      <div className="col-md-12">
      <Link to="/"><i className="fa fa-angle-left back-btn" /></Link>
      <div className="qz title">Choose Subject for Test</div>
      </div>
    </div>
    <div className="row">
        {
            data && data.length>0 && data.map((value,key)=>
            
            <div className="col-12 col-md-4" key={key}>
            <div className="card shadows">
              <div className="subjects-section">
                <figure>
                 <Link to={'/subject/'+value.slug}> <img src={value.image} alt="" /></Link>
                </figure>
                <div className="contant">
        <div className="name"> <Link to={'/subject/'+value.slug}>{value.name}</Link></div>
                  {/* <div className="chapter"><span>14</span> Chapter</div> */}
        <div className="quiz"><span>{value.quiz_count}</span> Test</div>
                </div>
              </div>
            </div>
          </div>
         
            )
        }
     
     </div>
  </div>
</section>

           </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      user : state.user,
      data : state.data
    }  
  }
  
  const mapDispatchToProps = (dispatch) =>{
      return { 
        updateData : (data)=>{dispatch({type:'APPEND', payload : data })}
      }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Subjecttest); 
