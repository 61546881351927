import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
// import { Helmet } from 'react-helmet';
// import Loading from './template/Loading';

 
class About extends Component<any,any>{

    componentDidMount() {
        window.scrollTo(0, 0)
      }

    render()
    {
        return (
       <div></div>
        )
    }

   
}

export default About; 