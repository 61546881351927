import React, { Component } from 'react'

export default class Filter extends Component<any,any> {

    constructor(props)
    {
      super(props); 
      this.state = {
        data : {}
      }
    }
    
    componentDidMount()
    {
      //console.log(this.props);
    }

    render() {
        return (
           <React.Fragment>
                <div className="filter-title">Filter</div>
        <div className="filter">
          <ul>
            <li>
        <span className="badge num">{this.props.not_visited}</span>
              NOT VISITED
            </li>
            <li>
              <span className="badge gren">{this.props.answered}</span>
              ANSWERED
            </li>
            <li>
              <span className="badge dk-red">{this.props.not_answered}</span>
              NOT ANSWERED
            </li>
            <li>
              <span className="badge dk-pl">{this.props.marked_review}</span>
              MARKED FOR REVIEW
            </li>
            <li>
              <span className="badge mix">{this.props.answered_and_reviewed}</span>
              ANSWERED AND MARKED FOR REVIEW
            </li>
          </ul> 
        </div>
           </React.Fragment>
        )
    }
}
