import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ScriptFile from './ScriptFile';
export default class header extends Component {
  render() {
    return (
     <React.Fragment>
    <ScriptFile/>
   <header>
  <div className="container">
    <div className="row top">
      <div className="col-4 col-md-6">
        <div className="logo">
          <img src="/practiceus-assets/image/practiceus-logo2.png" alt="Practiceus logo" className="img-fluid" />
        </div>
      </div>
      <div className="col-8 col-md-6">
        <div className="get-app">
          <a href="?#" data-toggle="modal" id="loginButton" data-target="#login">Login</a>&nbsp;&nbsp;
          <a href="?#" data-toggle="modal" id="registerButton" data-target="#register">Enroll</a>&nbsp;&nbsp;
          <a href="https://practiceus.com/blog" >Blog</a>&nbsp;&nbsp;
        </div>
      </div>
    </div>
    <div className="row baner-text">
      <div className="col-md-9">
        <div className="row">
          <div className="col-md-12">
          <div className="free-acceess">
          THE ONLY AND MOST COMPREHENSIVE <div className="big">BITSAT </div> 
              <span>PRACTICE PLAN</span>
            </div> 
            <ul className="banner-ul">
              <li>15+ HIGH QUALITY PRACTICE TESTS</li>
              <li>40+ SUBJECT TESTS</li>
              <li>50+ CHAPTER TESTS</li>
              <li>10+ PREVIOUS YEARS QUESTION PAPERS ANALYSIS</li>
              <li>FOCUS AREA TESTS</li>
              </ul>
            <div className="title">
              <span>INR <strong>3999/-</strong> ONLY</span>
              <Link style={{color:'#76c93b'}} to="/how-it-work">How Does It Works?</Link>
            </div>

            <div className="enroll">
              <a href="#?" data-toggle="modal" data-target="#register">Enroll Now</a>
            </div>
          </div>
          {/* <div className="col-md-5">
            <ul className="chapter">
              <li>
                <figure>
                  <img src="/practiceus-assets/image/Biology.png" alt="" />
                </figure>
                <div className="content">
                  <div className="ttl">Biology</div>
                  <div className="chapter">14 Chapter</div>
                  <div className="quiz">140 Quiz</div>
                </div>
              </li>
              <li>
                <figure>
                  <img src="/practiceus-assets/image/Physics.png" alt="" />
                </figure>
                <div className="content">
                  <div className="ttl">Physics</div>
                  <div className="chapter">14 Chapter</div>
                  <div className="quiz">140 Quiz</div>
                </div>
              </li>
              <li>
                <figure>
                  <img src="/practiceus-assets/image/Chemistry.png" alt="" />
                </figure>
                <div className="content">
                  <div className="ttl">Chemistry</div>
                  <div className="chapter">14 Chapter</div>
                  <div className="quiz">140 Quiz</div>
                </div>
              </li>
              <li>
                <figure>
                  <img src="/practiceus-assets/image/Mathematics.png" alt="" />
                </figure>
                <div className="content">
                  <div className="ttl">Mathematics</div>
                  <div className="chapter">14 Chapter</div>
                  <div className="quiz">140 Quiz</div>
                </div>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  </div>
</header>

       </React.Fragment>
    )
  }
}
