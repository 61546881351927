import React, { Component } from "react";
// import logo from "./logo.svg";
import "./App.css";
import Common from "./component/Common";
class App extends Component {
  render() {
    return (
        <Common/>
    );
  }
}

export default App;
