import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Axios from 'axios';
import Loading from '../template/Loading';
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import Chart from 'react-google-charts';

class Result extends Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      url: process.env.REACT_APP_API_URL,
      user: this.props.user,
      redirect: null,
      isLoading: true
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.stopFullScreenMode();
    this.setState({
      isLoading: false,
      data: {
        api: this.props.data.resultAPI
      }
    });
    if (this.props.data.resultAPI === undefined) {
      window.location.href = "/";
    }
  }

  stopFullScreenMode()
  {
        document.exitFullscreen()
    .then(function() {
      // element has exited fullscreen mode
    })
    .catch(function(error) {
      // element could not exit fullscreen mode
      // error message
      console.log(error.message);
    });
  }

  googleAccuracyChart(accuracy) {
  
    let arr:any[] = []; 
    let title:any[] = ['Total', 'Number of Questions']; 
    arr.push(title);
    // let total_attempted:any[] = ['Attempted', parseInt(accuracy.total_attempted_question)];
    let total_correct_ans:any[] = ['Corrected', parseInt(accuracy.total_correct_ans)];
    let total_incorrect_ans:any[] = ['Incorrected', parseInt(accuracy.total_incorrect_ans)];
    let total_not_attempted:any[] = ['Not Answered', parseInt(accuracy.total_not_attempted)];
  
    arr.push(total_correct_ans);
    arr.push(total_incorrect_ans);
    arr.push(total_not_attempted);
    //console.log(arr);
    return <Chart
    width={'300px'}
    height={'300px'}
    chartType="PieChart"
    loader={<div>Loading Chart</div>}
    // data={[
    //   ['Total', ''],
    //   ['Work', 11],
    //   ['Eat', 2],
    //   ['Commute', 2],
    //   ['Watch TV', 2],
    //   ['Sleep', 7],
    // ]}
    data = { arr }
    options={{
      title: 'Quiz Accuracy Report',
      // Just add this option
      pieHole: 0.4,
    }}
    rootProps={{ 'data-testid': '3' }}
  />

  }

  googleBarChart(level) {
    let arr: any[] = [];
    let titles = ['Level', 'Not Answered', 'Correct', 'Incorrect'];
    arr.push(titles);


    let low:any[] = ['Low', level.low.notAnswered, level.low.correct, level.low.incorrect]; 
    let medium:any[] = ['Medium', level.medium.notAnswered, level.medium.correct, level.medium.incorrect]; 
    let hard:any[] = ['Hard', level.hard.notAnswered, level.hard.correct, level.hard.incorrect]; 

    arr.push(low);
    arr.push(medium);
    arr.push(hard);
    // for(let key in level)
    // {
    //   if (level.hasOwnProperty(key)) { 
    //       let child_arr:any[] = []; 
    //       let level_type = level[key];
    //       for(let k in level_type)
    //       {
    //         if(level_type.hasOwnProperty(k))
    //         {
    //           child_arr.push(level_type[k]);
    //         }
    //       }
    //       arr.push(child_arr);
    //   }
    // }

    return <Chart
            width={'300'}
            height={'300px'}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            // data={[
            // ['Level', 'Total Question', 'Correct', 'Incorrect'],
            // ['Hard', 1000, 400, 200],
            // ['Medium', 1170, 460, 250],
            // ['Low', 660, 1120, 300],
            // ]}
            data = { arr } 
            options={{
            // Material design options
            chart: {
            title: 'Question Level Performance',
            subtitle: '',
            },
            }}
            // For tests
            rootProps={{ 'data-testid': '2' }}
            />
  }


  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    if (this.state.isLoading === true) return <Loading />
    let data = this.state.data.api;
    let questiondata = this.state.data.api.questions_data;
    let sections = data.sections; 
    let sections_arr = Object.keys(sections);
    return (
      <React.Fragment>
        <Helmet>
          <title>Result - Quiz App</title>
        </Helmet>
        <section>
          <div className="container wraper">
            <div className="row">
              <div className="col-md-8 bor-rt">
                <div className="result-title">Result: <span>{data.quizdata.name}</span></div>
                {/* Repeat Result 1 */}

                {
                  questiondata && questiondata.length > 0 && questiondata.map((value, key) =>

                    <div className="repeat-result" key={key}>
                      <div className="card mb0 pb50">
                <p><strong>{++key}</strong></p>
                        {ReactHtmlParser(value.name)}
                        <div className="ans">
                          <ul>
                            <li className={(value.correctans === 1) ? 'active' : (value.user_answer === 1) ? 'wrong' : ''}>
                              <strong>A</strong>{ReactHtmlParser(value.option1)}
                            </li>
                            <li className={(value.correctans === 2) ? 'active' : (value.user_answer === 2) ? 'wrong' : ''}>
                              <strong>B</strong>{ReactHtmlParser(value.option2)}
                            </li>
                            <li className={(value.correctans === 3) ? 'active' : (value.user_answer === 3) ? 'wrong' : ''}>
                              <strong>C</strong>{ReactHtmlParser(value.option3)}
                            </li>
                            <li className={(value.correctans === 4) ? 'active' : (value.user_answer === 4) ? 'wrong' : ''}>
                              <strong>D</strong>{ReactHtmlParser(value.option4)}
                            </li>
                          </ul>
                        </div>
                        <div className="result-stick">
                          <span>Your Answer: {value.user_answer}</span>
                          <span>Correct Answer: <i>{value.correctans}</i></span>
                          <span className="time-taken">{(value.user_time !== null) ? 'Your Time: ' + value.user_time : ''}</span>
                        </div>
                      </div>
                      <div className="explanation">
                        <div className="title">Explanation</div>
                        {ReactHtmlParser(value.explantions)}
                      </div>
                    </div>

                  )
                }

              </div>
              <div className="col-md-4">
                <div className="result-title invisible">Result: </div>
                <div className="result-stats">
                  <ul className="score-blk">
                    <li>
                      <div className="ttl">Your Score</div>
                      <div className="score blue">{data.total_score_earned}/{data.total_score}</div>
                    </li>
                    <li>
                      <div className="ttl">Attempted <span>Question</span></div>
                      <div className="score drk-blue">{data.total_attempted_question}/{data.total_question}</div>
                    </li>
                    <li>
                      <div className="ttl">Correctly <span>Answered</span></div>
                      <div className="score drk-blue">{data.total_correct_ans}/{data.total_question}</div>
                    </li>
                    <li>
                      <div className="ttl">Duration</div>
                      <div className="score orange">{data.duration}</div>
                    </li>
                    <li>
                      <div className="ttl">Negative <span>Marks</span></div>
                      <div className="score">{data.negative_mark}</div>
                    </li>

                    {
                      sections_arr.length > 0 && sections_arr.map((value,key)=>(
                        <li key={key}>
                        <div className="ttl">{value}</div>
                        <div >
                        <div className="score blue" style={{fontSize : '11px' }}>Score : {sections[sections_arr[key]]['score']} /  {sections[sections_arr[key]]['total']} </div>
                        <div style={{fontSize : '11px' }} className="score grn" >Correct : {sections[sections_arr[key]]['correct']} /  {sections[sections_arr[key]]['count']} </div>
                        <div style={{fontSize : '11px' }} className="score">Incorrect : {sections[sections_arr[key]]['incorrect']} /  {sections[sections_arr[key]]['count']} </div>
                        <div style={{fontSize : '11px' }} className="score drk-blue">Not Attempted : {sections[sections_arr[key]]['not_attempted']} /  {sections[sections_arr[key]]['count']} </div>
                        </div>
                      </li>
                      ))
                    }
                  </ul>
                </div>
                <div className="analysis">
                  <div className="ttl">ANALYSIS</div>
                  <div className="card">
                    <div className="chart">
                      <div id="barchart_values" />
                      {this.googleAccuracyChart(this.state.data.api.graph.accuracy)}
                    </div>
                    <div className="chart">
                      <div id="chart_div" />
                      {this.googleBarChart(this.state.data.api.graph.level)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div></section>



      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    data: state.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateData: (data) => { dispatch({ type: 'APPEND', payload: data }) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Result); 
