import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import ReactHtmlParser  from 'react-html-parser';
class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                  <footer>
	© 2021. All Rights Reserved by Practiceus
</footer>
         
            </React.Fragment>
        )
    }
}

export default Footer;