
exports.firebaseConfig = {
    apiKey: "AIzaSyAeaNHZUnJTT2M8rHK06XB8vfbwKf_r26Q",
    authDomain: "auth.steamdaily.com",
    databaseURL: "https://steamdaily-85dfe.firebaseio.com",
    projectId: "steamdaily-85dfe",
    storageBucket: "steamdaily-85dfe.appspot.com",
    messagingSenderId: "870358505774",
    appId: "1:870358505774:web:beb0f0729c9284dfe742bf",
    measurementId: "G-XKQBRBDRQ5"
  };