import React, { Component } from 'react'
import ScriptFile from './beforeLogin/ScriptFile';
import Footer from './beforeLogin/footer';
import { Link } from 'react-router-dom';
export default class Terms extends Component<any,any> {
    render() {
        let token = this.props.location.hash;
        return (
            <React.Fragment>
                   <ScriptFile/>
   <header>
  <div className="container">
    <div className="row top">
      <div className="col-4 col-md-6">
        <div className="logo">
          <Link to="/"><img src="/practiceus-assets/image/practiceus-logo2.png" alt="Practiceus logo" className="img-fluid" /></Link>
        </div>
      </div>
      <div className="col-8 col-md-6">
        <div className="get-app">
          <a href="?#" data-toggle="modal" id="loginButton" data-target="#login">Login</a>&nbsp;&nbsp;
          <a href="?#" data-toggle="modal" id="registerButton" data-target="#register">Enroll</a>&nbsp;&nbsp;
          <a href="https://practiceus.com/blog" >Blog</a>&nbsp;&nbsp;
        </div>
      </div>
    </div>
    <div className="row baner-text">
      <div className="col-md-9">
        <div className="row">
          <div className="col-md-12">
          <div className="free-acceess">
             Terms Of Use 
            </div> 
           
            <p>The Practiceus Website and the Practiceus App are brought to you by Eyas Ventures (OPC) Pvt Ltd (“EyVen”). Access and use of the Service is subject to the following terms and conditions (“Terms of Use”). Please read these Terms of Use,Privacy Policy, and the other applicable rules, policies, and terms posted on the Practiceus.com website or provided with the Service (collectively, this "Agreement") before using any aspect of the Service. By using the Service, or using any aspect of the Service, you agree to be bound by the terms of this Agreement. If you do not accept the terms of this Agreement, then you may not use the Service.</p>
            <p>These Terms of Use constitute an electronic record within the meaning of the applicable law. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>

            <h3>1. Enrollment</h3>
                <p>To use the service you have to enroll with Practiceus for which , you agree to:</p>
                <ul>
                <li>Giving  accurate, current and complete information about you;</li>
                <li>Ensure that you maintain the confidentiality of your account and not share passwords;</li>
                <li>Ensure that any information you have provided Practiceus is current and correct </li>
                <li>You assume complete responsibility for all use of your account and for any actions that take place through your account; and</li>
                <li>Respond to our communication by all means like email, phone etc.</li>
                </ul>
                <p>In the event any mandatory information is not provided, the registration shall be deemed to be incomplete and use of the Service may be restricted or blocked. By registering, you certify that all information you provide, now or in the future, is accurate.</p>
            <h3>2. Use of the App</h3>
            <p>You may use the App only on Supported Devices. The App will use Wi-fi or mobile data to download data for the App. Where Wi-fi is not available, the App will default to mobile data for downloads, which may occur without prompting the user. You may incur data charges upon usage of mobile data as per your mobile plan.</p>
            <h3>3. Eligibility</h3>
            <p>Use of the Service is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are a minor i.e., under the age of 18 years, you may use the Service only with the involvement of a parent or guardian. By using the Service, you represent and warrant that you have the authority and capacity to abide by this Agreement.</p>
            <h3>License</h3>
            <p>We hereby grant you a limited, non-exclusive, non-assignable, non-transferable and revocable license to access the Service solely for your personal, non-commercial use, solely on the Supported Devices and expressly conditioned upon your agreement that all such access and use shall be governed by this Agreement. The Content is licensed, not sold, to you by the Content Provider. You hereby expressly agree that you will use the Service only in a manner consistent with this Agreement.</p>

            <h3>Limitations</h3>
            <p> Unless specifically indicated otherwise, you may not sell, rent, lease, distribute, broadcast, sublicense, or otherwise assign any rights to the Content or any portion of it to any third party, and you may not remove or modify any proprietary notices or labels on the Content. In addition, you may not attempt to bypass, modify, defeat, or otherwise circumvent any digital rights management system or other content protection or features used as part of the Service.
            </p>

            <h3>Copyrights and Trademarks</h3>
            <p>The Service and all accompanying materials are copyright protected by Practiceus or its providers. All rights reserved. Access and use of the Service does not grant or provide you with the right or license to reproduce, remove, alter or otherwise use any of the copyrights or trademarks or registered trademarks of Practiceus. Content provided on the Service may be created by Practiceus, procured from third party Content Providers, or sourced from available resources</p>
            <p>All Content including the question papers provided on Practiceus is provided on an “as is” basis and Practiceus makes no representation or warranty as to the adequacy, accuracy, completeness, reliability or correctness of such Content obtained from a third party entity.</p>
            <h3>Liability</h3>
            <p>You expressly agree that any access or use of the Service and the Content is at your sole risk. Practiceus reserves the right, in its sole discretion and without prior notice, to modify, terminate, or suspend the Service at any time. Practiceus is not responsible for injury or damage resulting from downloading any materials in connection with the Service. The Service may be temporarily unavailable from time to time due to required maintenance, telecommunication interruptions, or other disruptions. Access and use of the Service may be unavailable during periods of peak demand, system upgrades, malfunctions or scheduled or unscheduled maintenance or for other reasons.
            Practiceus and/or its associated entities make no representations or warranties or conditions of any kind relating to the accuracy, suitability, completeness, result of use and error-free operation of the Service and its contents, information, software, products, features and services, or the Content, for any purpose.
            Any analytical reports generated pursuant to your use of the Service, including your overall rank and percentile or comparison with other customers is limited to customers using the Service and is not indicative of your actual comparison with other students. Usage of our Services does not in any manner guarantee achievement of the same or similar percentile, rank or score in any examinations or admission to any educational institutions.
            In no event shall Practiceus and/or its associated entities be liable for any direct, indirect, punitive, incidental, special or consequential damages arising out of or in any way connected with the use of the Service, or the delay in or inability to access or use or risk of loss of, the Service or any portion of it, or for any contents, information, software, products, features and services obtained through the Service, including but not limited to the loss of use of the Service, inaccurate results, loss of profits, business interruption, or damages stemming from loss or corruption of data or data being rendered inaccurate or incomplete, the cost of recovering any data, the cost of substitute services or claims by third parties for any damage to computers, software, modems, telephones or other property, or otherwise arising out of the use of the Service, whether based on contract, tort, strict liability or otherwise.
            Practiceus reserves all rights and remedies available by law and in equity for any violation of this Agreement.
            </p>
            <h3>Termination</h3>
            <p>Practiceus reserves the right, at its sole discretion, to alter, amend or terminate your access to the Service or any portion of it, at any time, without prior notice, including for any violation of this Agreement, any unauthorised use of the Service by you or assignment or transfer of the rights granted to you. Any termination by Practiceus shall not affect any right or relief to which Practiceus may be entitled, at law or in equity.
You may terminate your Service at any time by following the instructions available through the Service.
The provisions relating to representations and warranties, intellectual property, indemnification, liability, and miscellaneous provision of this Agreement shall survive any termination.
</p>
<h3>Miscellaneous</h3>
<p>Information Provided to Practiceus. The Service will provide Practiceus with information about the use of your Practiceus Application and its interaction with Content and the Service (such as questions attempted, content archiving, available memory, up-time, log files, and signal strength). You authorise Practiceus to use your interactions with the Service to enable functionality of the Service including to determine your rank, personalise Content to be displayed to you and other Service features which do not work without using your data. You also authorise Practiceus to transfer your interactions with the Service to any third party service providers who may analyse this data on our behalf. If you do not consent to the use and transfer of your data or interactions with the Service in this manner, do not use the Service. Information provided to Practiceus may be stored on servers that are located outside the country in which you live. 
Changes to Service; Amendments. We may modify, suspend, or discontinue the Service, in whole or in part, including adding or removing Content from a Service, at any time without notice. We may amend any of this Agreement's terms at our sole discretion by posting the revised terms on the Website. Your continued use of the App or any aspect of the Service after the effective date of the revised Agreement terms constitutes your acceptance of the terms.</p>
<p>
This Agreement constitutes the entire agreement between Practiceus and you concerning the subject matter hereof. In the event that any of the terms of this Agreement are held by a court or tribunal of competent jurisdiction to be unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary so that this Agreement shall otherwise remain in full force and effect. A waiver by Practiceus or you of any provision of this Agreement or any breach of this Agreement, in any one instance, will not waive such term or condition or any subsequent breach of this Agreement. Practiceus may assign its rights or obligations under this Agreement.
Disputes. Any dispute or claim arising from or relating to this Agreement or the Service is subject to the governing law, disclaimer of warranties and limitation of liability and all other terms in the Practiceus.in Conditions of Use. You agree to those terms by entering into this Agreement, purchasing or using Content, or using any aspect of the Service.
Contact Information. For help with the Service, Content, this Agreement or resolving other issues, please contact Customer Service by tapping emailing to contact@practiceus.com
</p>
            <div className="enroll">
              <a href="#?" data-toggle="modal" data-target="#register">Enroll Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
                <Footer token={token}/>
            </React.Fragment>
        )
    }
}
