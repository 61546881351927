import React, { Component } from 'react';
import * as firebase from "firebase/app";
import "firebase/auth";
import config from "../config";
  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(config.firebaseConfig);
}
// firebase.analytics();
export default class Logout extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        firebase.auth().signOut()
        .then(function() {
            localStorage.clear();
            window.location.href="/";
        })
        .catch(function(error) {
            console.error('something went wrong');
        });
        localStorage.clear();
      }

    render() {
        return (
            <React.Fragment>
            <section>
  <div className="container wraper">
    <div className="row">
      <div className="col-md-12">
        <div className="qz title">Please wait ..</div>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-12">
       
          <div className="text-center">
            You are now logging out..
          </div>
        
      </div>
     </div>
      
    </div>
</section>
           </React.Fragment>
        );
    }
}
