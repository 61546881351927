import React, { Component } from 'react'
import Loader  from 'react-loader-spinner'

export default class Laoding extends Component {

  componentDidMount()
  {
    let payment_status = localStorage.getItem('payment_status');
    let current_path = window.location.pathname; 
    if(payment_status === 'pending' && current_path !== '/upgrade' && current_path !== '/refer')
    {
      window.location.href = "/upgrade";
    }
  }
  
    render() {  
        let  type:any[] = ["Audio", "BallTriangle", "Bars", "Circles", "Grid", "Hearts", "Oval", "Puff", "Rings", "TailSpin", "ThreeDots"];
        let  randomType = type[Math.floor(Math.random() * type.length)];
        return (
            <React.Fragment>
<section>
  <div className="container wraper">
    <div className="row">
      <div className="col-md-12">
        <div className="qz title">Loading ..</div>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-12">
       
          <div className="text-center">
          <Loader
                    type={randomType}
                    color="#6bc334"
                    height={300}
                    width={300}
                    />
          </div>
        
      </div>
     </div>
      
    </div>
</section>


            </React.Fragment>
                
        )
    }
}
