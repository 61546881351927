export default class Helper {
    inArray(needle, haystack) {
        var length = haystack.length;
        for(var i = 0; i < length; i++) {
            if(haystack[i] === needle) return true;
        }
        return false;
    }

    arrayUnique(arr)
    {
        let uniquearr :any[] = []; 
        for(let value of arr)
        {
            if(!this.inArray(value,uniquearr))
            {
                uniquearr.push(value);
            }
        }
        return uniquearr; 
    }

    uniqueAnswers(answers)
    {
        let uniqueArr = [] as any;
        let i = 0;
        for(let value of answers)
        {
            if(!this.inArray(value.question_id, uniqueArr))
            {
                uniqueArr.push(value.question_id);
            }
            else
            {
                answers.pop(answers[i]);
            }
        }
        return answers;
    }

    removeValueFromArray(arr, value)
    {
      if(this.inArray(value, arr))
      {
        let index = arr.indexOf(value); 
        let new_arr = arr.splice(index, 1); 
       return new_arr; 
      }
      else
      {
        return arr; 
      }
    }

    /* 
    *  Get Questions array from question id from the sets of all questions
    */
    getQuestionsFromQuestionID(questions, question_ids)
    {
        let required_questions:any[] = [];
        for(let value of questions)
        {
            if(question_ids.includes(value.id))
            {
                required_questions.push(value); 
            }
        }
        return required_questions; 
    }   

}