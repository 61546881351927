import React, { Component } from 'react'
import {Link} from 'react-router-dom';

export default class Error extends Component<any,any> {

    constructor(props)
    {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
<section>
  <div className="container wraper">
    <div className="row">
      <div className="col-md-12">
        <div className="qz title">Error</div>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-12">
       
          <div className="text-center">
                    <div className="error-template">
                <h1>
                    Oops!</h1>
                <h2>{ (this.props?.error_title === undefined) ? 'Something went wrong' : this.props?.error_title }</h2>
                <div className="error-details">
                { (this.props?.error_message === undefined) ? 'Some error occured, please go to home' : this.props?.error_message }
                </div>

                <br/>
                <div className="error-actions">
                    <Link to='/' className="btn btn-primary btn-lg"><span className="glyphicon glyphicon-home" />
                    Take Me Home </Link>
                </div>
                </div>

          </div>
        
      </div>
     </div>
      
    </div>
</section>


            </React.Fragment>
                
        )
    }
}
