import Axios from 'axios';
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import Loading from '../template/Loading';


export default class Refer extends Component<any,any> {

    constructor(props:any)
    {
        super(props);
        this.state = {
            posts : [],
            isLoading : true,
            invitation_number : 5, 
            email : [],
            data : {}, //used this state for saving random data 
            url : process.env.REACT_APP_API_URL, //api url,
            invite_button : 'Invite Now',
            refer_url : ''
        }
    }


    componentDidMount()
    {
      this.getReferral(); 

    }

    getReferral()
    {
      let th = this; 
      Axios({
        method: 'GET', 
        url : this.state.url+'refer/get-referral',
        headers:{
          Authorization : 'Bearer '+ localStorage.getItem('token'),
          Accept : 'application/json'
        }
      })
      .then((res)=>{
        console.log(res.data.data);
          th.setState({
            data : {
              api : res.data.data
            },
            refer_url : res.data.refer_url,
            isLoading : false
          });
      });  
    }

    copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        ToastsStore.success('Referral Link Copied');
        return true; 
      };

    addMoreInvitations()
    {
        let invite = this.state.invitation_number;
        this.setState({
            invitation_number : invite+1
        });  
    }

    validateEmail(mail) 
    {
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
      {
          return true;
      }
          return false;
    }



    InvitationEmailHTML()
    {
       let email_inputs:any[] = []; 
       let email_number = this.state.invitation_number; 
       for(let i=0; i< email_number; i++)
       {
          let temp:any
             temp = (<div className="col-md-2 col-sm-12"><input className="ref_custom_input" name={"email"+i} placeholder="Email" type="email"/></div>);
           email_inputs.push(temp);
       }
       return email_inputs;
    }


    handleInvitation(e)
    {
        e.preventDefault();
        this.setState({
          invite_button : 'Please Wait'
        });
        let arr:any[] = []; 
        for(let i=0; i < this.state.invitation_number; i++)
        {
                let value = e.target[i].value;
                if(value !== undefined && value !== "")
                {
                    arr.push(value);
                }
        }

      if(arr.length === 0)
      {
        ToastsStore.error("Please Enter atleast one email!");
        this.setState({
          invite_button : 'Invite Again'
        });
      }
      else
      {
         this.sentInvitation(arr,true,false);
      }
    }

    resendEmail(e,email)
    {
      e.preventDefault();
      ToastsStore.info('Sending Mail to '+email);
      let arr:any[] = [email];
      this.sentInvitation(arr,false,true);
    }

    sentInvitation(emails,invite_button=false, resend_link = false)
    {
      let th = this; 
      Axios({
          method:'POST',
          data:{
            emails : emails
          }, 
          url :  this.state.url+'refer/invite',
          headers:{
            Authorization : 'Bearer '+ localStorage.getItem('token'),
            Accept : 'application/json'
          }
      })
      .then((data)=>{
        ToastsStore.success('Invitation Sent Successfully');
        if(invite_button === true)
        {
          th.setState({
            invite_button : 'Invite More'
            });
            this.getReferral();
        }

        if(resend_link === true)
        {
          th.setState({
            resend_link : 'Resend'
            });
        }       
      })
      .catch((err)=>{
        if(resend_link === true)
        {
          th.setState({
            resend_link : 'Resend'
            });
        }     

        if(invite_button === true)
        {
          th.setState({
            invite_button : 'Invite More'
            });
        }
        console.error(err); 
        ToastsStore.error('Something went wrong, please try after sometime');
      });
    }

    render() {
      if (this.state.isLoading === true) return <Loading />

      let data = this.state.data.api; 
        return (   
            <React.Fragment>
                <Helmet>
                    <title>Referral Page</title>
                </Helmet>
               <section className="referral_lst_page"><div className="container">
    <div className="row">
      <div className="col-md-12">
        <h1 className="referral_heading">Your Referral List</h1>
      </div>
      <div className="col-md-12">
        <table className="table table-borderless table_referral">
          <thead>
            <tr>
              <th>Invitee</th>
              <th>Date of Sending Invite</th>
              <th>Date of Accepting</th>
            </tr>
          </thead>
          <tbody>
           {
             data.length > 0 && data.map((value,key)=>
             <tr key={key}>
             <td>{value.refer_email}</td>
             <td>{value.created_at}</td>
             <td>
             {
               value.isAccepted === 0 ? 
               <React.Fragment>Pending <a href='?#' className='referral_resend' onClick={(e)=>this.resendEmail(e,value.refer_email)}>Resend</a></React.Fragment>
               : 
               value.accepted_at
             }
             </td>
           </tr>
             ) 
           } 
           
          </tbody>
        </table>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <h1 className="referral_heading">Invite</h1>
      </div>
      <div className="col-md-12">
        <div className="copy_text_group"> 
          <div className="referral_text">{this.state.refer_url}
            <span className="ref_copy_img"><a href="#?" onClick={()=>this.copyToClipboard(this.state.refer_url)}><img className="ref_text_copy" alt="" src="/assets/img/copy.png" /></a></span>
          </div>
        </div>
      </div>
    </div>
   
    
     <form onSubmit={(e)=>this.handleInvitation(e)}>
     <div className="row">
            { this.InvitationEmailHTML() }
      </div>
      <div className="row">
        <div className="col-md-12">
        <div className="invt_group">
          <a className="invite_text" href="#?" onClick={()=>this.addMoreInvitations()}>+ Invite More for more 30 Days</a>
        </div>
      </div>
      </div>

      <div className="row">
      <div className="col-md-12">
        <button type="submit" className="ref_inv_btn">{this.state.invite_button}</button>
      </div>
    </div>

    </form>
  </div>
</section>
<ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>
            </React.Fragment>
        )
    }
}
