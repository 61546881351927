import Axios from 'axios';
import React, { Component } from 'react'
import Helmet from 'react-helmet';

export default class Updateprofile extends Component<any,any> {

    constructor(props:any)
    {
        super(props);
        this.state={
            url : process.env.REACT_APP_API_URL, 
            errors : [], 
            success: false,
            submitButton : 'Submit', 
            show : false,
            data : {},
            redirect: null,
            selectedFile : null,
            user :{}
        };
    }

    componentDidMount()
    {
        this.setState({
            user : {
            name : localStorage.getItem('name'),
            email : localStorage.getItem('email'),
            phone : localStorage.getItem('phone'),
            photoUrl : localStorage.getItem('photoURL'),
        }})
    }

    formError()
    {
      let errors = this.state.errors;
      if(errors === undefined || errors === null)
      {
          return false;
      }
      var err_list:any = []; 

      Object.values(errors).forEach((value:any) => {
        value && value.length > 0 && value.map((item:any,key:any)=>
          err_list.push(item)
        ) 
      });
      return (
        <React.Fragment>
         
        <ul className="error">
         { 
         err_list && err_list.length > 0 && err_list.map((item:any, key:any) =>
      <li key={key}>{item}</li>
          )
         }
        </ul>
        </React.Fragment>
      ); 

    }   

   

    handleProfile(e)
    {
        e.preventDefault();

        this.setState({
            submitButton : 'Please Wait', 
            error : []
        });
        const formData = new FormData(); 
        if(this.state.selectedFile !== null)
        {
            formData.append(
                "avatar", 
                this.state.selectedFile
              ); 
        }
      
        formData.append("name", e.target.name.value);
        formData.append("phone", e.target.phone.value);
        let name = e.target.name.value;
        let phone = e.target.phone.value;
        let th = this; 
        Axios({
            method : 'POST', 
            data : formData,
            url  : this.state.url+'users/update-profile', 
            headers:{
              Authorization : 'Bearer '+localStorage.getItem('token'),
              Accept : 'application/json'
            }
        })
        .then((data)=>{
            console.log(data.data.data.photoUrl);
                th.setState({
                    submitButton : 'Success',
                    errors : [],
                    success : true, 
                    user : data.data.data
                });
                localStorage.setItem('name', name);
                localStorage.setItem('email', th.state.user.email);
                localStorage.setItem('photoURL', data.data.data.photoUrl);
                localStorage.setItem('phone', phone);
        })
        .catch(function(err){
            th.setState({
              errors : err.response.data.errors, 
              submitButton : 'Submit Again', 
            });
          }); 
    }

    onFileChange = event => { 
        this.setState({ selectedFile: event.target.files[0] }); 
      }; 

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title> Update Profile</title>
                </Helmet>
                <section>
                    <div className="container wraper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="qz title">Update Profile </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-wrap-big">
                                    <div className="table">
                                        {this.formError()}
                                        {
                                            (this.state.success === true) ? 
                                        
                                        <div className="alert alert-success">
                                            Profile Update Successfully
                                        </div>
                                        : ''
                                        }
                                        <form onSubmit={(e)=>this.handleProfile(e)}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Name:</label>
                                                        <input type="text" className="form-control" placeholder="Enter name" name="name" defaultValue={this.state.user?.name || ''} pattern=".{4,}" title="Four or more characters" />
                                                    </div>
                                                </div>  
                                                <div className="col-md-6">
                                                     <div className="form-group">
                                                        <label htmlFor="email">Email:</label>
                                                        <input type="email" className="form-control" placeholder="Enter email" defaultValue={this.state.user?.email || ''} disabled />
                                                    </div>
                                                </div> 
                                                <div className="col-md-6">
                                                     <div className="form-group">
                                                        <label htmlFor="phone">Phone:</label>
                                                        <input type="number" className="form-control" placeholder="Enter phone" name="phone" defaultValue={this.state.user.phone || ''} min="1" pattern="{10}" title="Ten Digit required" required/>
                                                    </div>
                                                </div> 
                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="class">Class:</label>
                                                        <input type="number" className="form-control" placeholder="Enter class" name="class"  defaultValue={this.state.user.class_name || ''} min="1" max="20" pattern="{2}"/>
                                                    </div>
                                                </div>  */}
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="profile">Avatar:</label>
                                                        <input type="file" className="form-control"  onChange={this.onFileChange}/>
                                                    </div>
                                                </div> 

                                                <div className="col-md-6">
                                                <img src={this.state.user.photoUrl} style={{width:100}} alt="avatar"/>
                                                </div>
                                                 
                                            </div>
                                           
                                         <button type="submit" className="btn btn-primary">{this.state.submitButton}</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </React.Fragment>
        )
    }
}
