import Axios from 'axios';
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Redirect } from 'react-router-dom';

export default class ChangePassword extends Component<any,any> {
    constructor(props:any)
    {
        super(props);
        this.state={
            url : process.env.REACT_APP_API_URL, 
            errors : [], 
            success: '',
            submitButton : 'Submit', 
            show : false,
            data : {},
            redirect: null
        };
    }

    handleChangePassword(e)
    {       
        e.preventDefault();
        this.setState({
            submitButton : 'Please Wait'
        });
        let data:any={};
        let th = this;
        data.current_password = e.target.password.value;
        data.password = e.target.np.value;
        data.password_confirmation = e.target.rnp.value;
        Axios({
            method : 'POST', 
            data : data,
            url  : this.state.url+'users/change-password', 
            headers:{
              Authorization : 'Bearer '+localStorage.getItem('token'),
              Accept : 'application/json'
            }
        })
        .then((data)=>{
                th.setState({
                    submitButton : 'Success',
                    errors : [],
                    redirect : '/logout'
                })
        })
        .catch(function(err){
            
            th.setState({
              errors : err.response.data.errors, 
              submitButton : 'Submit Again', 
            });
          }); 
    }


    formError()
    {
      let errors = this.state.errors;
      if(errors === undefined || errors === null)
      {
          return false;
      }
      var err_list:any = []; 

      Object.values(errors).forEach((value:any) => {
        value && value.length > 0 && value.map((item:any,key:any)=>
          err_list.push(item)
        ) 
      });
      return (
        <React.Fragment>
          <p className="error">{this.state.data.socialLoginError}</p>
        <ul className="error">
         { 
         err_list && err_list.length > 0 && err_list.map((item:any, key:any) =>
      <li key={key}>{item}</li>
          )
         }
        </ul>
        </React.Fragment>
      ); 

    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
          }
        return (
            <React.Fragment>
                <Helmet>
                    <title> Change Password </title>
                </Helmet>
                <section>
                    <div className="container wraper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="qz title">Change Password </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-wrap-big">
                                    <div className="table">
                                        {this.formError()}
                                        <form onSubmit={(e)=>this.handleChangePassword(e)}>
                                            <div className="form-group">
                                                <label htmlFor="password">Current Password:</label>
                                                <input type="password" className="form-control" placeholder="Enter password" name="password" pattern=".{8,}" title="Eight or more characters" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="new-password">New Password:</label>
                                                <input type="password" className="form-control" placeholder="Enter new password" name="np" pattern=".{8,}" title="Eight or more characters" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="pwd">Repeat New Password:</label>
                                                <input type="password" className="form-control" placeholder="Repeat password" name="rnp" pattern=".{8,}" title="Eight or more characters" />
                                            </div>
                                          
        <button type="submit" className="btn btn-primary">{this.state.submitButton}</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </React.Fragment>
        )
    }
}
