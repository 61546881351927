import React, { Component } from 'react'
import ScriptFile from './beforeLogin/ScriptFile';
import Footer from './beforeLogin/footer';
import { Link } from 'react-router-dom';
export default class HowItWork extends Component<any,any> {
    render() {
        let token = this.props.location.hash;
        return (
            <React.Fragment>
                   <ScriptFile/>
   <header>
  <div className="container">
    <div className="row top">
      <div className="col-4 col-md-6">
        <div className="logo">
          <Link to="/"><img src="/practiceus-assets/image/practiceus-logo2.png" alt="Practiceus logo" className="img-fluid" /></Link>
        </div>
      </div>
      <div className="col-8 col-md-6">
        <div className="get-app">
          <a href="?#" data-toggle="modal" id="loginButton" data-target="#login">Login</a>&nbsp;&nbsp;
          <a href="?#" data-toggle="modal" id="registerButton" data-target="#register">Enroll</a>&nbsp;&nbsp;
          <a href="https://practiceus.com/blog" >Blog</a>&nbsp;&nbsp;
        </div>
      </div>
    </div>
    <div className="row baner-text">
      <div className="col-md-9">
        <div className="row">
          <div className="col-md-12">
          <div className="free-acceess">
          How does it work?
            </div> 
            <p>
<ul>
    <li> How do I use Practiceus for my BITSAT preparation ?
We have a simple task on our hands, where ever you study , come practise with Practiceus. And we have just one focus BITSAT. We have devised a program that will help you prepare for the BITSAT exam, amid all that you are doing, preparing for JEE ( Mains and Advanced), you Board exams and a few others. The program consists of , Full Tests, Subject Tests,Chapter Tests, Focus Area Tests and  Past Papers. Towards the end of the program we will also series of questions we believe will be critical for BITSAT Curated Questions That We Expect.</li>
<li> Does the Program cover the entire BITSAT Syllabus?
Yes, it covers the entire syllabus as mentioned in the BITSAT brochure.</li>
<li>How many Test papers are there in the Programs.
    There are 
<ul>
    <li>25 full length Tests (150 questions and 180 Mins)</li>
    <li>50 Subject Tests ( these Tests have different number of questions (40 for Chemistry and Physics, 45 for Maths and 25 for English and Reasoning)</li>
    <li>100 Chapter Tests ( 25 questions) </li>
    <li>Previous Years Question Papers</li>
</ul>
</li>
<li>
    How does the program work?
Once you sign up we will send you a schedule which we believe is going to work the best for you. You could however chose to make your own plans based on the your schedule.
    </li>
    <li> What are the Other Features of the Program.</li>
    <li>As we will come close to the exam our research team consisting of experienced teachers will constantly send you tips, to improve your scores. We will also conduct webinars to help you improve outcomes.   </li>
</ul></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
                <Footer token={token}/>
            </React.Fragment>
        )
    }
}
