import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import Loading from '../template/Loading';
import { Helmet } from 'react-helmet';
import { Redirect, Link } from 'react-router-dom';
import { Chart } from "react-google-charts";

class Dashboard2 extends Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      url: process.env.REACT_APP_API_URL,
      data: {},
      isLoading: true,
      redirect: null
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAPIData();
  }


  getAPIData() {
    //if check whether api exists on reducer or not, if reducer is empty load api once again 

    if (this.props.data.user_dashboard === undefined) {
      let th = this;
      Axios({
        method: 'GET',
        data: {},
        url: this.state.url + 'dashboard',
        headers: {
          Authorization: 'Bearer ' + this.state.user.token,
          Accept: 'application/json'
        }
      })
        .then((res) => {
          console.log(res.data.message);
          let msg = res.data.message.toString(); 
          if(msg === 'PAYMENT_PENDING')
          {
            th.setState({
              redirect : '/logout'
            });
          }
          else
          {
            th.setState({
              data: {
                subjects: res.data.data.subjects,
                fulltest: res.data.data.fulltest,
                graph: res.data.data.graph,
                isFresh : res.data.data.isFresh
              },
              isLoading: false
            });
            th.props.updateData({ user_dashboard: res.data.data });
          }
          
        })
        .catch((err) => {
          if (err.response?.data?.message === 'Unauthenticated.') {
            console.log('redirecting now..');
            th.setState({
              redirect: '/logout'
            });
          }
        });
    }
    else {
      let response = this.props.data.user_dashboard;
      this.setState({
        data: {
          subjects: response.subjects,
          fulltest: response.fulltest,
          graph: response.graph,
          isFresh : response.isFresh
        },
        isLoading: false
      });
    }
  }


  googleChart(chartData) {
   // console.log(chartData);

    // let titles:any[] = [];
    // for(let title of chartData)
    // {
    //   titles.push(title.quizname);
    // }
    // titles.unshift('Attempts');
    // let arr:any[]=[]; 
    // arr.push(titles);
    // let j =0; 
    // for(let i=0;i<5;i++)
    // {
    //   let child_arr:any[] = []; 
    //   child_arr.push('Attempt '+ ++j);
    //   for(let value of chartData)
    //   {
    //     child_arr.push(parseInt(value.attempt[i].total_score));
    //   }
    //   arr.push(child_arr);
    // }
    // console.log(arr);
    return <Chart
      width={'600px'}
      height={'400px'}
      chartType="LineChart"
      loader={<div>Loading Chart</div>}
      data={[
        ['Attempt', 'Quiz1', 'Quiz2', 'Quiz3', 'Quiz4'],
        [1, 100, 101, 110, 123],
        [2, 120, 133, 145, 132],
        [3, 124, 143, 231, 124],
        [4, 132, 124, 143, 143],
        [5, 124, 143, 154, 164]
      ]}
      // data = { arr }
      options={{
        hAxis: {
          title: 'Attempts',
        },
        vAxis: {
          title: 'Score',
        },
      }}
      rootProps={{ 'data-testid': '1' }}
    />
  }


  freshUserHtml()
  {
    return (
     <React.Fragment>
      <div className="row">
        <div className="col-md-4">
          <Link to="/chapters">
          <div className="card mb40">
            {/* <div className="sb-hd">A shot Test</div> */}
            <div className="test">
            <span style={{ color : '#414141'}}>Chapter Test</span>
              
            </div>
          </div>
          </Link>
        </div>
        <div className="col-md-4">
        <Link to="/subjects">
          <div className="card mb40">
            {/* <div className="sb-hd">Full Subject Test</div> */}
            <div className="test">
            <span style={{ color : '#414141'}}>Subject Test</span>
              
            </div>
          </div>
          </Link>
        </div>
        <div className="col-md-4">
        <Link to="/full-test">
          <div className="card mb40">
            {/* <div className="sb-hd">Full Course Test</div> */}
            <div className="test">
            <span style={{ color : '#414141'}}>Full Course Test</span>
              
            </div>
          </div>
          </Link>
        </div>
      </div>
     </React.Fragment>
    );
  }

  graphHtml()
  {
    return (
    <React.Fragment>
        <div className="row">
              <div className="col-md-8">
                <div className="card show-over">
                  <div className="hd">Your Performance</div>
                  {this.googleChart(this.state.data.graph)}
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="percent">
                    <table>
                      <tbody><tr>
                        <td><div className="waterdrop"><span>40%</span></div></td>
                        <td><div className="waterdrop yellow"><span>40%</span></div></td>
                        <td><div className="waterdrop"><span>40%</span></div></td>
                        <td><div className="waterdrop yellow"><span>40%</span></div></td>
                      </tr>
                      </tbody></table>
                  </div>
                  <div className="rating">
                    <div className="blk1">
                      <div className="txt">Lorem ipsum</div>
                      <span className="fa fa-heart checked" />
                      <span className="fa fa-heart checked" />
                      <span className="fa fa-heart checked" />
                      <span className="fa fa-heart checked" />
                      <span className="fa fa-heart checked" />
                      <span className="fa fa-heart checked" />
                      <span className="fa fa-heart checked" />
                      <span className="fa fa-heart" />
                      <span className="fa fa-heart" />
                      <span className="fa fa-heart" />
                      <span className="percent">70%</span>
                    </div>
                    <div className="blk1">
                      <div className="txt">Lorem ipsum</div>
                      <span className="fa fa-heart checked yellow" />
                      <span className="fa fa-heart checked yellow" />
                      <span className="fa fa-heart checked yellow" />
                      <span className="fa fa-heart checked yellow" />
                      <span className="fa fa-heart checked yellow" />
                      <span className="fa fa-heart checked yellow" />
                      <span className="fa fa-heart checked yellow" />
                      <span className="fa fa-heart checked yellow" />
                      <span className="fa fa-heart checked yellow" />
                      <span className="fa fa-heart" />
                      <span className="percent">90%</span>
                    </div>
                    <div className="blk1">
                      <div className="txt">Lorem ipsum</div>
                      <span className="fa fa-heart checked" />
                      <span className="fa fa-heart checked" />
                      <span className="fa fa-heart" />
                      <span className="fa fa-heart" />
                      <span className="fa fa-heart" />
                      <span className="fa fa-heart" />
                      <span className="fa fa-heart" />
                      <span className="fa fa-heart" />
                      <span className="fa fa-heart" />
                      <span className="fa fa-heart" />
                      <span className="percent">20%</span>
                    </div>
                  </div>
                  <div className="random">
                    <div className="blk">
                      <figure>
                        <img src="/assets/img/brain.png" alt="" />
                      </figure>
                      <div className="bar">
                        <span className="name">Brain</span>
                        <div className="wrap">
                          <div className="progress">
                            <div className="progress-bar" style={{ width: '25%' }} role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                          </div>
                          <span className="value">25%</span>
                        </div>
                        <div className="wrap">
                          <div className="progress">
                            <div className="progress-bar yellow" style={{ width: '40%' }} role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                          </div>
                          <span className="value">40%</span>
                        </div>
                      </div>
                    </div>
                    <div className="blk">
                      <figure>
                        <img src="/assets/img/Heart.png" alt="" />
                      </figure>
                      <div className="bar">
                        <span className="name">Heart</span>
                        <div className="wrap">
                          <div className="progress">
                            <div className="progress-bar" style={{ width: '25%' }} role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                          </div>
                          <span className="value">25%</span>
                        </div>
                        <div className="wrap">
                          <div className="progress">
                            <div className="progress-bar yellow" style={{ width: '40%' }} role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                          </div>
                          <span className="value">40%</span>
                        </div>
                      </div>
                    </div>
                    <div className="blk">
                      <figure>
                        <img src="/assets/img/lung.png" alt="" />
                      </figure>
                      <div className="bar">
                        <span className="name">lung</span>
                        <div className="wrap">
                          <div className="progress">
                            <div className="progress-bar" style={{ width: '25%' }} role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                          </div>
                          <span className="value">25%</span>
                        </div>
                        <div className="wrap">
                          <div className="progress">
                            <div className="progress-bar yellow" style={{ width: '40%' }} role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                          </div>
                          <span className="value">40%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
    </React.Fragment>
    )
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    if (this.state.isLoading === true) return <Loading />
    let subjects = this.state.data.subjects;
    let fulltest = this.state.data.fulltest;
    return (
      <React.Fragment>
        <Helmet>
          <title>Dashboard - Practiceus</title>
        </Helmet>
        <section>
          <div className="container wraper">
            <div className="row">
              <div className="col-md-12">
                <div className="qz title">Dashboard</div>
              </div>
            </div>

            {
              (this.state.data.isFresh === 'yes')? this.freshUserHtml() : this.freshUserHtml() 
            }

            <div className="row">
              <div className="col-md-5">
                <div className="card p0">
                  <div className="hd1">Chapter Tests</div>
                  <ul className="subjects">
                    {

                      subjects && subjects.length > 0 && subjects.map((value, key) =>
                        <li key={key}>
                          <figure>
                            <img src={value.image} alt="" />
                          </figure>
                          <div className="cotnent">
                            <div className="name"> <Link to={'/chapter/' + value.slug}>{value.name}</Link></div>
                            <div className="chapter"><span>{value.chapter_count}</span> Chapter</div>
                            <div className="quiz"><span>{value.quiz_count}</span> Quiz</div>
                          </div>
                        </li>

                      )

                    }


                  </ul>
                </div>
              </div>
              <div className="col-md-7">
                <div className="card p0">
                  <div className="hd1">Full Tests</div>
                  <div className="table-wrap">
                    <div className="table">
                      {/* Row 1 */}

                      {
                        fulltest && fulltest.length > 1 && fulltest.map((value, key) =>

                          <div className="table-row" key={key}>
                            <div className="table-cell bor-lt">
                              {value.name}
                            </div>
                            <div className="table-cell bor-rt text-right">
                              <Link to={"/start-test/" + value.slug} className="start-btn">START</Link>
                            </div>
                          </div>

                        )
                      }
                    
                    </div>
                    <Link to={"/full-test"} className="start-btn" style={ { 'float' : 'right' } } >More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const matchPropToState = (state) => {
  return {
    user: state.user,
    data: state.data,
    user_dashboard: state.user_dashboard,
  }
}

const dispatchPropToState = (dispatch) => {
  return {
    updateData: (data) => { dispatch({ type: 'APPEND', payload: data }) }
  }
}

export default connect(matchPropToState, dispatchPropToState)(Dashboard2); 