import Axios from 'axios';
import axios from 'axios';
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import Loading from '../template/Loading';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

export default class payment_page extends Component<any,any> {

    constructor(props: any) {
        super(props);
        this.state = {
          url: process.env.REACT_APP_API_URL,
          errors: [],
          success: '',
          submitButton: 'Submit',
          show: false,
          data: {},
          redirect_to : false,
          isLoading:true,
          coupon_button : 'Apply',
          coupon_applied : false, 
          page_heading : ''
        };
      }
    componentDidMount()
    {
      let status = localStorage.getItem('payment_status');  // if user has not expire, then his payment status is completed, whether he is paid or not 
      let isPaid = localStorage.getItem('isPaid');
        if(isPaid === 'yes')
        {
            this.setState({
                redirect_to : '/'
            });
        }
        else
        {
          if(status === 'pending')
          {
            this.setState({
              page_heading : 'Complete your payment to get unlimited benefits'//'Oops! Your have exhausted your limit.'
            });
          }
          else if(isPaid === 'no')
          {
            this.setState({
              page_heading : 'Upgrade now for unlimited benefits, You can upgrade any time'
            });
          }
            this.createOrder(); 
        }
        
    }

   
    createOrder() {
        let th = this;
        let data = { 
            user_id : localStorage.getItem('user_id')
        }
        axios({
          method: 'POST',
          url: this.state.url + 'payment/create-order',
          data : data,
          headers:{
            Authorization : 'Bearer '+ localStorage.getItem('token'),
            Accept : 'application/json'
          }
        })
          .then(function (res) {
            let status = res.data.message.toString(); 
            if(status === 'PAYMENT_COMPLETED')
            {
              localStorage.setItem('payment_status', 'completed');
              localStorage.setItem('isPaid', 'yes');
                th.setState({
                  redirect_to : '/'
              });
            }
            else
            {
              th.setState({
                submitButton: 'Success',
                isLoading : false,
                data : res.data.data
              });
            }
            
            
          })
          .catch(function (err) {
                if (err.response?.data?.message === 'Unauthenticated.') {
                  console.log('redirecting now..');
                  th.setState({
                    redirect_to: '/logout'
                  });
                }
            th.setState({
              errors: err?.response?.data.errors,
              submitButton: 'Submit Again',
              isLoading : false
            });
          });
      }


      applyCoupon(e)
      {
        e.preventDefault(); 
        let th = this; 
        this.setState({
            coupon_button : 'Please Wait..'
        });
        let code = e.target.code.value; 
        let order_id = this.state.data.order_id; 
        Axios({
            method : 'POST', 
            url : this.state.url+'payment/apply-coupon', 
            data : {
                coupon_code : code, 
                order_id : order_id
            }, 
            headers:{
                Authorization : 'Bearer '+ localStorage.getItem('token'),
                Accept : 'application/json'
              }
        })
        .then((res)=>{
            if(res.data.status === 'error')
            {
                th.setState({
                    coupon_button : 'Apply'
                });
                ToastsStore.error(res.data.message);
            }
            else
            {
                th.setState({
                    coupon_button : 'Apply',
                    coupon_applied: true, 
                    data : res.data.data
                });
                ToastsStore.success(res.data.message);
            }
        })
        .catch((err) => {
            if (err.response?.data?.message === 'Unauthenticated.') {
              console.log('redirecting now..');
              th.setState({
                redirect_to: '/logout'
              });
            }
          });
      }

      removeCoupon()
      {
        let th = this;
        let order_id = this.state.data.order_id;  
        Axios({
            method : 'POST', 
            url : this.state.url+'payment/remove-coupon',
            data : {
                order_id : order_id
            }, 
            headers:{
                Authorization : 'Bearer '+ localStorage.getItem('token'),
                Accept : 'application/json'
              }
        })
        .then((res)=>{
            if(res.data.status === 'error')
            {
                ToastsStore.error(res.data.message);
            }
            else
            {
                th.setState({
                    coupon_button : 'Apply',
                    coupon_applied: false, 
                    data : res.data.data
                });
                ToastsStore.success(res.data.message);
            }
        })
        .catch((err) => {
            if (err.response?.data?.message === 'Unauthenticated.') {
              console.log('redirecting now..');
              th.setState({
                redirect_to: '/logout'
              });
            }
          });
      }

    render() {
        if(this.state.redirect_to)
        {
            return <Redirect to={this.state.redirect_to} />
        }
        if(this.state.isLoading) return <Loading/>

        let order = this.state.data; 
        return (
            <React.Fragment>
<div className="container">
  <div className="row">
    <div className="col-md-12">
      <div className="common-wrap">
        <div className="head-blk after-non">
          <h1 className="ttl">{this.state.page_heading}</h1>
          {/* <p className="sb-ttl">Don’t worry. Refer to 5 friends and get 30 days access free.</p> */}
          {/* <div className="actions">
            <Link to="/refer" className="button">Refer</Link>
          </div> */}
        </div>
        {/* <div className="or">
          <span>OR</span>
        </div> */}
        <div className="upgrade">
          <div className="ttl">Total Payable Amount is  ₹{order.amount}/- only.</div>
          {
              (this.state.coupon_applied) ? <div className="applied"> ( Coupon Applied Successfully <a href="#?" onClick={()=>this.removeCoupon()}><i className="fa fa-close"></i></a> )</div> :
              <div className="coupon">
                    <form onSubmit={(e)=>this.applyCoupon(e)}>
                    <input type="text" name="code" className="form-group" placeholder="Coupon code" required/>
                    <button type="submit" className="couponApply">{this.state.coupon_button}</button>
                    </form>
                </div>
          }
          
       
          <div className="actions">
          <form action="https://store.steamdaily.com/payment/" method="POST">
                <input type="hidden" name="firstname" value={order.name} />
                <input type="hidden" name="order_id" value={order.id} />
                <input type="hidden" name="email" value={order.email} />
                <input type="hidden" name="phone" value={order.phone} />
                <input type="hidden" name="amount" value={order.amount} />
                <input type="hidden" name="productinfo" value={order.productinfo} />
                <input type="hidden" name="surl" value={order.surl} />
                <input type="hidden" name="furl" value={order.furl} />
                <input type="hidden" name="curl" value={order.curl} />
            <button type="submit"  className="button">Pay Now</button>
        </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>

            </React.Fragment>
        )
    }
}
