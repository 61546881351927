import React, { Component } from 'react';
import Loading from './template/Loading';
import Dashboard2 from './dashboard/Dashboard2';
import {connect} from 'react-redux';
class Dashboard extends Component<any,any>{

   constructor(props:any)
   {
      super(props);
      this.state = {
        user : this.props.user,
         posts : [],
         isLoading : false
     }
   }
   componentDidMount() {
      window.scrollTo(0, 0)
    }

    render(){
      if (this.state.isLoading === true) return <Loading/>
      return <Dashboard2/>
    }
    
    
  }

 const mapStateToProps = (state) => {
    return {
      user : state.user
    }  
  }

export default connect(mapStateToProps)(Dashboard); 