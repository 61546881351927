import React, { Component } from 'react'
import { Link } from 'react-router-dom';
export default class QuickLinks extends Component<any,any> {

     

    render() {
      let questions = this.props.questions;
        return (
      <React.Fragment>
     <div className="qz-timer-links">
  <ul>
    {
      questions && questions.length > 0 && questions.map((value,key)=>

      <li key={key}>
      <div className="progressbar">
        <div className="second circle" data-percent={20}><canvas width={30} height={30} />
          <Link to={'/test/question/'+value.id}>
    <span className="num">{++key}</span>
          </Link>
        </div>
      </div>
    </li>

      )
    }
  </ul>
</div>

      </React.Fragment>
        )
    }
}
