import React, { Component } from 'react'
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Axios from 'axios';
import Loading from '../template/Loading';
import { Redirect, Link } from 'react-router-dom';
// import Filter from './Quiz/Filter';
import ReactHtmlParser from 'react-html-parser';
import Helper from './Quiz/Helper';
import Timer from 'react-compound-timer';
import Error from '../template/Error';
class Question extends Component<any, any> {
  protected helper;
  constructor(props) {
    super(props);
    this.state = {
      data: {}, //used this state for saving random data 
      url: process.env.REACT_APP_API_URL, //api url
      user: this.props.user,
      redirect: null, //redirect to any component as per requirement
      isLoading: true, //show loader true or false
      timer: {},  //timer related data will be saved here 
      activeClass: {  // on selecting any ans make the selected answer highlighted 
        optionA: 'inactive',
        optionB: 'inactive',
        optionC: 'inactive',
        optionD: 'inactive',
      },
      answers: [],  //final answers (contain array by question id and selected options in a object)
      tempAnswer: {}, //temp answers when user select an option, will be reset on next/prev questions
      not_answers: [], //questions which is left by user (array of question id) (Not answered)
      reviewed: [],  //question which are marked for reviewed (array of question_id)
      answered: [], //questions which are answered (array of question_id)
      answered_reviewed_arr: [], //answered and reviewed array (array of question id)
      not_visited: [], //array of question id which is not yet visited
      review_status: false,
      interruption: true,
      all_questions: '',
      error: false,
      error_data: {},
      quizdata: '',
      filter_page: {
        title: false,
        questions: false
      }, // find this variable used in filterPage() function, 
      interval : ''
    }
    this.helper = new Helper();
  }

  /**
   * When Page is loaded fully 
   * @return void
   * @param null
   */

  componentDidMount() {
    this.checkPropValue();
    window.scrollTo(0, 0);
    let question_id = this.props.match.params.question_id;
    let th = this;
    this.getAPIData(question_id, function () {
      let all_question = th.props.data?.all_questions;
      let not_visited: any[] = [];
      for (let value of all_question) {
        not_visited.push(value.id);
      }
      th.helper.removeValueFromArray(not_visited, question_id);
      th.setState({
        all_questions: th.props.data.all_questions,
        quizdata: th.props.data.current_quiz.quiz,
        not_visited: not_visited
      });

    });
    this.setStartTime();
    this.keepMeAlive();
  }

  keepMeAlive(destroy=false)
  {
    let th = this;
    let interval = setInterval(function(){
      Axios({
        method: 'GET',
        data: {},
        url: th.state.url + 'quiz/keep-alive',
        headers: {
          Authorization: 'Bearer ' + th.state.user.token,
          Accept: 'application/json'
        }
      })
        .then((res) => {
          //console.log(res.data.message);
        })
        .catch((err) => {
          if (err.response?.data?.message === 'Unauthenticated.') {
            th.setState({
              redirect: '/logout'
            });
          }
          //console.log(err.response?.data);
        })
    }, 120000); //120 sec 
    this.setState({
      interval : interval
    });
  }


  /* 
  * On Exam complete exit the full screen 
  * Currently not using it
  */
  stopFullScreenMode() {
    document.exitFullscreen()
      .then(function () {
        // element has exited fullscreen mode
      })
      .catch(function (error) {
        // element could not exit fullscreen mode
        // error message
        console.log(error.message);
      });
  }

  checkPropValue() {
    if (Object.keys(this.props.data).length === 0) {
      this.setState({
        error: true,
        error_data: {
          title: 'Quiz Page is Refreshed',
          message: "It seems that you have refreshed the page, Please start the quiz from begining"
        }
      });
    }
    else if (this.props.data.quizSubmissionStatus === true && this.props.data.submittedQuizId === parseInt(this.props.data.all_questions[0].questionset_id)) {
      this.setState({
        error: true,
        error_data: {
          title: 'Quiz Already Completed',
          message: "It seems that you have already completed this quiz, please start it again or go to home"
        }
      });
    }


  }

  /**
   * this function triggers when page is going to unload
   * @return void
   * @param null
   */
  componentWillUnmount() {
    //this.stopFullScreenMode();
    // if(this.state.interruption === true)
    // {
    // alert('Quiz interrupt');
    // } 
    clearInterval(this.state.interval);
 }

  /**
   * Set the timer time when page is loaded and when clicked on next button save the current time and continue where the last left 
   */
  setStartTime() {
    var d = new Date(); //current date
    var start_time = d.getTime(); //current time
    this.setState({
      timer: {
        startAt: this.props.data.timer?.startAt,
        direction: this.props.data.timer?.direction, //forward or backward
        start_time: start_time,
        start_time_forward: this.props.data.timer?.start_time
      }
    });
  }

  /**
   * Need to set end time so that on nex question time can be get
   */
  setEndTime() {
    let start_time = this.state.timer.start_time;
    var date = new Date();
    var end_time = date.getTime();
    let difference;
    let start_time_forward;
    if (this.state.timer.direction === 'backward') {
      difference = Math.abs(end_time - start_time - this.state.timer.startAt); //get value in positive always 
    }
    else {
      start_time_forward = this.state.timer.start_time_forward;
      difference = end_time - start_time_forward;
    }

    this.setState({
      timer: {
        start_time: end_time, //end time will become start time for next circle if direction is backward
        startAt: difference,
        direction: this.state.timer.direction,
        start_time_forward: start_time_forward
      }
    });
  }

  /**
   * When next button is clicked this function will triggered everytime 
   */
  onClickNextButton(question_id = null) {
    window.scrollTo(0, 0);
    this.setEndTime();
    this.saveAnswer(); //save selected answer on click next
    //preparing for calling the api
    this.setState({
      isLoading: true
    });

    if (question_id === null) {
      question_id = this.state.data.apiData.data.next_question_id;
    }
    let th = this;
    this.getAPIData(question_id, function () {
      th.setState({
        // make higlighted area inactive on clicking next
        activeClass: {
          optionA: 'inactive',
          optionB: 'inactive',
          optionC: 'inactive',
          optionD: 'inactive',
        }, 
        filter_page : {
          title : false, 
          questions : false
        }
      });
      th.checkReviewStatus(); //check for review button on clicking next button (hitting this function after api is called)
      th.checkAnswerStatus(); //check for already selected answers 

    });
  }

  /**
   * When previous button is clicked, following function is triggered
   */

  onClickPrevButton() {
    window.scrollTo(0, 0);
    this.setEndTime();
    this.saveAnswer(); //save selected answer on click prev

    //preparing for calling the api, show the loader
    this.setState({
      isLoading: true
    });

    let question_id = this.state.data.apiData.data.prev_question_id;
    let th = this;

    //calling api
    this.getAPIData(question_id, function () {
      th.setState({
        // make higlighted area inactive on clicking prev
        activeClass: {
          optionA: 'inactive',
          optionB: 'inactive',
          optionC: 'inactive',
          optionD: 'inactive',
        }
      });
      th.checkReviewStatus(); //check for review button on clicking prev button (hitting this function after api is called)
      th.checkAnswerStatus(); //check for already selected answers 
    });
  }

  /**
   * Check whether this question is marked for reviewed or not (checked or unchecked)
   * when a question is loaded this function triggeres, generally when user clicks on next or prev button
   * this function is called after api is loaded, so that it can be assured, new question is fully loaded
   */
  checkReviewStatus() {
    let question_id = this.props.match.params.question_id;
    let reviewed = this.state.reviewed;
    let answered_and_review = this.state.answered_reviewed_arr;

    if (this.helper.inArray(question_id, reviewed)) {
      this.setState({
        review_status: true
      });
    }
    else if (this.helper.inArray(question_id, answered_and_review)) {
      this.setState({
        review_status: true
      });
    }
    else {
      this.setState({
        review_status: false
      });
    }
  }

  /**
   * when user move between the questions by clicking next or prev button, this function will check whther the question is already selected or not, if user is revisiting the same question, selected question will marked by default
   * this function will trigger after page is fully loaded, so this is placed when api is called
   */
  checkAnswerStatus() {
    let question_id = this.props.match.params.question_id;
    let answers = this.state.answers;
    let selected_ans = null;
    for (let value of answers) {
      if (value.question_id === question_id) {
        selected_ans = value.selected_ans
      }
    }
    if (selected_ans !== null) {
      this.setState({
        activeClass: {
          optionA: (selected_ans === '1') ? 'active' : 'inactive',
          optionB: (selected_ans === '2') ? 'active' : 'inactive',
          optionC: (selected_ans === '3') ? 'active' : 'inactive',
          optionD: (selected_ans === '4') ? 'active' : 'inactive'
        },
        tempAnswer: {
          question_id: question_id,
          selected_ans: selected_ans
        }
      });
    }
  }


  /**
   * getting the final duration time after submitting the quiz
   */
  getFinalQuizDuration() {
    let start_time = this.state.timer.start_time;
    var date = new Date();
    var end_time = date.getTime();
    let difference;
    difference = Math.abs(end_time - start_time - this.state.timer.startAt);
    return difference;
  }

  /* 
  * When user submit the quiz 
  */
  onClickSubmitButton() {
    this.saveAnswer(); //save selected answer on click next
    //collection required data 
    this.setState({
      isLoading: true
    });
    let quiz_id = this.state.data.apiData.data.questionset_id;
    let answered_count = this.props.data.current_quiz.quiz.attempted_count;
    let duration = this.getFinalQuizDuration();
    let type = this.props.data.timer.direction;
    let questions = this.state.answers;
    let filter = {
      not_answered: this.state.not_answers,
      reviewed: this.state.reviewed,
      answered_and_reviewed: this.state.answered_reviewed_arr
    }

    let data = {
      quiz_id: quiz_id,
      duration: duration,
      type: type,
      questions: questions,
      filters: filter,
      attempted_count: answered_count
    }
    let th = this;
    Axios({
      method: 'POST',
      data: data,
      url: this.state.url + 'quiz/submit',
      headers: {
        Authorization: 'Bearer ' + this.state.user.token,
        Accept: 'application/json'
      }
    })
      .then((res) => {
        th.props.updateData({ resultAPI: res.data.data, quizSubmissionStatus: true, submittedQuizId: quiz_id });
        th.setState({
          interruption: false,
          redirect: '/test/result'
        });
      });
    // }
  }

  getAPIData(question_id, callback) {
    let th = this;
    Axios({
      method: 'POST',
      data: {
        question_id: question_id
      },
      url: this.state.url + 'quiz/get-questions',
      headers: {
        Authorization: 'Bearer ' + this.state.user.token,
        Accept: 'application/json'
      }
    })
      .then((res) => {
        th.setState({
          isLoading: false,
          data: {
            apiData: res.data
          }
        });
        callback();
      })
      .catch((err) => {
        if (err.response?.data?.message === 'Unauthenticated.') {
          th.setState({
            redirect: '/logout'
          });
        }
        console.log(err.response?.data);
      })

  }


  /**
   * @param e : current event
   * maiking the selected option highlighted 
   * When a option is selected by user
   */
  onOptionSelection(e) {
    let selectedAns = e.currentTarget.value;
    // let th = this;
    let question_id = this.props.match.params.question_id;
    let obj = {
      question_id: question_id,
      selected_ans: selectedAns,
      answer_time: null
    }
    this.onActionByUser('answer_selected');
    this.setState({
      activeClass: {
        optionA: (selectedAns === '1') ? 'active' : 'inactive',
        optionB: (selectedAns === '2') ? 'active' : 'inactive',
        optionC: (selectedAns === '3') ? 'active' : 'inactive',
        optionD: (selectedAns === '4') ? 'active' : 'inactive'
      },
      tempAnswer: obj
    });
  }



  /**
   * when user clicks on next or prev button this function will trigger before moving to next / prev question 
   * this function takes the current saved data from temp answer and saved it into the answers state 
   */
  saveAnswer() {
    let question_id = this.props.match.params.question_id;

    //remove question id from not visited array 
    let not_visited = this.state.not_visited;
    this.helper.removeValueFromArray(not_visited, question_id);
    let tempAnswer = this.state.tempAnswer;
    let answers = this.state.answers; //this the array where final answer with question id is saved
    let unanswers = this.state.not_answers; //array of unanswered question id 
    //let answered = this.state.answered; //answered questions 
    if (Object.keys(tempAnswer).length !== 0) {
      //if we are inserting value inside answered filter remove the same value from unaswered state (array)
      if (this.helper.inArray(question_id, unanswers)) {
        let i = 0; 
        for(let value of unanswers)
        {
          if(value === question_id)
          {
            unanswers.splice(i,1);
          }
        }
      }

      //if answer already exists in case of revisit, first remove them and then insert it
        let i =0; 
        for (let value of answers) {
          if (value.question_id === tempAnswer.question_id) {
            answers.splice(i, 1); //removing repeated
          }
          i++;
        }
      //now push the latest value
      answers.push(tempAnswer);
    }
    else {
      //if value is being inserted in the unaswered array then remove the same value from the answered 
      let i = 0;
      for (let value of answers) {
        if (value.question_id === question_id) {
          answers.splice(i, 1); //removing unanswered
        }
        i++;
      }
      //and push it into the unanswered 
      let reviewed = this.state.reviewed;
      if (reviewed.includes(question_id) === false) {
        unanswers.push(question_id); //push the value into not_answered state, which question is not answered 
      }
    }
    unanswers = this.helper.arrayUnique(unanswers);
    this.setState({
      tempAnswer: {},
      answers: answers,
      not_answers: unanswers
    });

  }



  onClearResponse() {
    this.onActionByUser('clear_response');
    this.setState({
      activeClass: {
        optionA: 'inactive',
        optionB: 'inactive',
        optionC: 'inactive',
        optionD: 'inactive',
      },
      tempAnswer: {}
    });
  }


  onMarkReview(e) //clicked on checkbox
  {
    let value = e.target.checked;
    this.setState({
      review_status: value
    });

    this.onActionByUser('review', value);
  }


  //get question button color
  getButtonQuestionButtonColor(question_id) {
    if (this.state.answered.includes(question_id)) {
      return "correct";
    }
    else if (this.state.not_answers.includes(question_id)) {
      return "not_answered";
    }
    else if (this.state.reviewed.includes(question_id)) {
      return "markreview";
    }
    else if (this.state.answered_reviewed_arr.includes(question_id)) {
      return "answeredandreview";
    }
    else {
      return "num";
    }
  }

  quickLinks(questions) {
    return (
      <React.Fragment>
        <div className="qz-timer-links">
          <ul>
            {
              questions && questions.length > 0 && questions.map((value, key) =>
                <Link key={key} onClick={() => this.onClickNextButton(value.id)} to={"/test/question/" + value.id}>
                  <li>
                    <div className={(value.id === this.props.match.params.question_id) ? 'progressbar active' : 'progressbar'}>
                      <div className="second circle" data-percent={20}><canvas width={30} height={30} />

                        <span className={"badge " + this.getButtonQuestionButtonColor(value.id)}>{++key}</span>

                      </div>
                    </div>
                  </li>
                </Link>
              )
            }
          </ul>
        </div>

      </React.Fragment>
    )
  }


  timer(startTime, direction) {
    return (
      <div>
        <Timer initialTime={startTime}
          direction={direction}
          checkpoints={[
            {
              time: 1, //time up
              callback: () => this.onClickSubmitButton()
            }
          ]}
        >

          <Timer.Hours /> hours &nbsp; &nbsp;
          <Timer.Minutes /> minutes&nbsp;&nbsp;
          <Timer.Seconds /> seconds&nbsp;&nbsp;


              </Timer>
      </div>
    )
  }

  /*
  * When user select an answer, clear response, review, click on next or prev this function will trigger
  */
  onActionByUser(key, review_value = false) {
    let question_id = this.props.match.params.question_id;
    let answered = this.state.answered;
    let not_answered = this.state.not_answers;
    let reviewed = this.state.reviewed;
    let answered_and_review = this.state.answered_reviewed_arr;
    let review_status = this.state.review_status;

    if (key === 'answer_selected') {
      this.helper.removeValueFromArray(not_answered, question_id);
      if (review_status === true && answered_and_review.includes(question_id) === false) {
        answered_and_review.push(question_id);
        this.helper.removeValueFromArray(answered, question_id);
        this.helper.removeValueFromArray(reviewed, question_id);
      }
      else {
        if (answered.includes(question_id) === false) {
          answered.push(question_id);
        }
      }
    }
    if (key === 'review') {
      if (review_value === true && answered.includes(question_id) === true) {
        this.helper.removeValueFromArray(answered, question_id);
        answered_and_review.push(question_id);
      }
      else if (review_value === false && answered_and_review.includes(question_id) === true) {
        this.helper.removeValueFromArray(answered_and_review, question_id);
        answered.push(question_id);
      }
      else {
        reviewed.push(question_id);
      }
    }

    if (key === 'clear_response') {
      if (answered_and_review.includes(question_id) === true) {
        this.helper.removeValueFromArray(answered_and_review, question_id);
        reviewed.push(question_id);
      }
      else if (answered.includes(question_id)) {
        this.helper.removeValueFromArray(answered, question_id);
      }
    }

    this.setState({
      reviewed: reviewed
    });
  }



  /*
  * On Clicking filter pages, this function will loaded according to condition , not answered, answered, review, and answered and reviewed questions will display
  */
  filterPage() {
    let filter_page = this.state.filter_page;
    let title = filter_page.title;
    let questions = filter_page.questions;
    if (title === false) return false;
    return (
      <React.Fragment>
        <div className="row">
          {
            questions && questions.length > 0 && questions.map((value, key) =>
              <div className="col-md-4" key={key}>
                <Link onClick={() => this.onClickNextButton(value.id)} to={"/test/question/" + value.id}>
                  <div className="card start-quiz mb30">
                    <strong>{++key}</strong>
                    <div className="questions">
                      {ReactHtmlParser(value.name)}
                    </div>
                  </div>
              </Link>
              </div>
            )
          }
          {
            (questions.length === 0) ?
              <div className="col-md-12">
                <div className="card start-quiz mb30">
                  <p><strong>No Question</strong></p>
                  <div className="questions">
                    <p>No Question found in this Section</p>
                  </div>
                </div>
              </div>
              : ''
          }
        </div>
      </React.Fragment>
    );
  }

 


  onClickFilterLink(title, question_ids) {
    let questions = this.helper.getQuestionsFromQuestionID(this.state.all_questions, question_ids);
    this.setState({
      filter_page: {
        title: title,
        questions: questions
      }
    });
  }

  filterLinks(not_visited, answered, not_answered, marked_review, answered_and_reviewed) {
    return (
      <React.Fragment>
        <div className="filter-title">Filter</div>
        <div className="filter">
          <ul>
            <li onClick={() => this.onClickFilterLink('Not Visited', not_visited)}>
              <span className="badge num">{not_visited.length}</span>
         NOT VISITED
       </li>
            <li onClick={() => this.onClickFilterLink('Answered', answered)}>
              <span className="badge gren">{answered.length}</span>
         ANSWERED
       </li>
            <li onClick={() => this.onClickFilterLink('Not Answered', not_answered)}>
              <span className="badge dk-red">{not_answered.length}</span>
         NOT ANSWERED
       </li>
            <li onClick={() => this.onClickFilterLink('Reviewed', marked_review)}>
              <span className="badge dk-pl">{marked_review.length}</span>
         MARKED FOR REVIEW
       </li>
            <li onClick={() => this.onClickFilterLink('Answered and Reviewed', answered_and_reviewed)}>
              <span className="badge mix">{answered_and_reviewed.length}</span>
         ANSWERED AND MARKED FOR REVIEW
       </li>
          </ul>
        </div>
      </React.Fragment>
    )
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    if (this.state.error === true) return <Error error_title={this.state.error_data.title} error_message={this.state.error_data.message} />
    if (this.state.isLoading === true) return <Loading />
    let data = this.state.data.apiData.data;
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.quizdata.name + ' Quiz Questions - Practiceus'}</title>
        </Helmet>
        <section>
          <div className="container wraper">
            <div className="row">
              <div className="col-md-3">
                <div className="qz title">{this.state.quizdata.name}</div>
              </div>
              <div className="col-md-9">
                {/* <Filter not_visited={this.state.not_visited.length} answered={this.state.answered.length} not_answered={this.state.not_answers.length} marked_review={this.state.reviewed.length} answered_and_reviewed={this.state.answered_reviewed_arr.length} /> */}
                {this.filterLinks(this.state.not_visited, this.state.answered, this.state.not_answers, this.state.reviewed, this.state.answered_reviewed_arr)}
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                {
                (this.state.filter_page.questions) ? this.filterPage() :
                  <div className="row" >
                    <div className="col-md-12">
                      <div className="card mb30">
                        {ReactHtmlParser(data.name)}
                        <div className="ans">
                          <ul>
                            <li className={this.state.activeClass.optionA}>
                              <strong>A</strong>{ReactHtmlParser(data.option1)}
                              <input type="radio" name="options" value="1" onClick={(e) => this.onOptionSelection(e)} />
                            </li>
                            <li className={this.state.activeClass.optionB}>
                              <strong>B</strong>{ReactHtmlParser(data.option2)}
                              <input type="radio" name="options" value="2" onClick={(e) => this.onOptionSelection(e)} />
                            </li>
                            <li className={this.state.activeClass.optionC}>
                              <strong>C</strong>{ReactHtmlParser(data.option3)}
                              <input type="radio" name="options" value="3" onClick={(e) => this.onOptionSelection(e)} />
                            </li>
                            <li className={this.state.activeClass.optionD}>
                              <strong>D</strong>{ReactHtmlParser(data.option4)}
                              <input type="radio" name="options" value="4" onClick={(e) => this.onOptionSelection(e)} />
                            </li>
                          </ul>
                        </div>
                        <div className="action">
                          <div className="left">
                            <ul>
                              <li>
                                <input type="checkbox" name="review" value="1" onChange={(e) => this.onMarkReview(e)} checked={this.state.review_status} />
                                <label>Mark For Review</label>
                              </li>
                              <li onClick={() => this.onClearResponse()} >Clear Response</li>
                            </ul>
                          </div>
                          <div className="right">
                            <ul>
                              {
                                (data.prev_question_id === null) ? '' : <li><Link onClick={() => this.onClickPrevButton()} to={"/test/question/" + data.prev_question_id}><i className="fa fa-angle-left" />Prev</Link></li>
                              }
                              {
                                (data.next_question_id === null) ? '' : <li><Link onClick={() => this.onClickNextButton()} to={"/test/question/" + data.next_question_id}>Next<i className="fa fa-angle-right" /></Link></li>
                              }

                              {
                                (data.next_question_id === null) ? <li><a href="#?" onClick={() => this.onClickSubmitButton()}>Submit<i className="fa fa-paper-plane " /></a></li> : ''
                              }

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      (data.length === 0) ?
                        <div className="col-md-12">
                          <div className="card start-quiz mb30">
                            <p><strong>Invalid Question</strong></p>
                            <div className="questions">
                              <p>Invalid Question found</p>
                            </div>
                          </div>
                        </div>

                        : ''
                    }
                  </div>

              }
              </div>
              <div className="col-md-4">
                <div className="fixposition">
                  <div className="row bor">
                      <div className="col-md-12">
                        <div className="qz-timer">
                          {this.timer(this.state.timer?.startAt, this.state.timer?.direction)}
                        </div>

                      </div>
                        {
                          (data.section !== null || data.section !== '') ? <div className="col-md-12"><h3><span style={{ marginLeft: '5%', marginTop: '1%' }}>{data.section}</span></h3></div> : ''
                        }
                      <div className="col-md-12">
                        {this.quickLinks(this.state.all_questions)}
                      </div>
                      
                    </div>
                  </div>
              </div>

            </div>
           




            


          </div>
        </section>


      </React.Fragment>
    )
  }
}



const mapStateToProps = (state) => {
  return {
    user: state.user,
    data: state.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateData: (data) => { dispatch({ type: 'APPEND', payload: data }) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Question);
