import React, { Component } from 'react'
import * as firebase from "firebase/app";
import "firebase/auth";
import config from "../../config";
import axios from 'axios';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
// Initialize Firebase
firebase.initializeApp(config.firebaseConfig);
// firebase.analytics();
class Footer extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      url: process.env.REACT_APP_API_URL,
      errors: [],
      success: '',
      submitButton: 'Submit',
      show: false,
      data: {},
      redirect_to : false,
      refer_token : ''
    };

    this.handleLoginForm = this.handleLoginForm.bind(this);
    this.handleRegisterForm = this.handleRegisterForm.bind(this);

  }

  

  socialLogin(user, loginType) {
    let data = {
      name: user.displayName,
      email: user.email,
      photoUrl: user.photoURL,
      login_type: loginType,
      uid: user.uid
    }
    let th = this;
    axios({
      method: 'POST',
      data: data,
      url: this.state.url + 'auth/social-login'
    })
      .then(function (res) {

        th.props.addUserData(res.data);
        localStorage.setItem('name', res.data.data.displayName);
        localStorage.setItem('email', res.data.data.email);
        localStorage.setItem('photoURL', res.data.data.photoUrl);
        localStorage.setItem('user_id', res.data.data.uid);
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('isFresh', res.data.data.isFresh);
        window.location.reload();
      })
      .catch(function (err) {
        console.error(err);
        th.setState({
          errors: err.response.data.errors
        });
      });
  }

  socialSignIn = (login_type) => {
    var provider;
    if (login_type === 'facebook') {
      provider = new firebase.auth.FacebookAuthProvider();
    }
    else {
      provider = new firebase.auth.GoogleAuthProvider();
    }

    var th = this;
    firebase.auth().signInWithPopup(provider).then(function (result: any) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // var token = result.credential.accessToken;
      //console.log('token: ' + token);

      // The signed-in user info.
      var user = result.user;
      //console.log(user);
      console.log(user);
      th.socialLogin(user, login_type);
      //window.location.href = "/create";
    }).catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      console.error('errorCode: ' + errorCode);

      var errorMessage = error.message;
      console.error('errorMessage: ' + errorMessage);
      th.setState({
        data: { socialLoginError: error.message }
      });
      // The email of the user's account used.
      var email = error.email;
      console.log('email: ' + email);

      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      console.log('credential: ' + credential);
      console.log(error);
    });
  }

  checkSignIn = () => {
    //check whether user is already signed in 
    firebase.auth().onAuthStateChanged(function (user: any) {
      if (user) {
        // console.log(user);
        if (localStorage.getItem('user_id') === null) {
          localStorage.setItem('name', user.displayName);
          localStorage.setItem('email', user.email);
          localStorage.setItem('photoURL', user.photoURL);
          localStorage.setItem('user_id', user.uid);
        }
        console.log('You are logged in as ' + localStorage.getItem('name'));
        //window.location.href = "/";
      } else {
        // No user is signed in.
        console.log('you are not yet signed in');
      }
    });
  }


  componentDidMount() {
    
    window.scrollTo(0, 0);
    this.handleEmailVerification();
    this.openResetForm();
    this.checkSignIn();
    this.openRegistrationForm(); 
  }


  handleLoginForm(e: any) {
    e.preventDefault();

    //setting remeber me
    if(e.target.rememberme.checked)
    {
        localStorage.setItem('remember_me', 'yes');
    }
    else
    {
      localStorage.setItem('remember_me', 'no');
    }

    this.setState({ submitButton: 'Please Wait..' });
    let th = this;
    let data: any = {};
    data.email = e.target.email.value;
    data.password = e.target.password.value;
    axios({
      method: 'POST',
      url: this.state.url + 'auth/login',
      data: data
    })
      .then(function (res) {
        
        th.setState({
          submitButton: 'Success',
          errors: []
        });
        
        let msg:any = res.data.message; 
          th.props.addUserData({ user: res.data.data });
          localStorage.setItem('name', res.data.data.name);
          localStorage.setItem('email', res.data.data.email);
          localStorage.setItem('photoURL', res.data.data.photoUrl);
          localStorage.setItem('class', res.data.data.class);
          localStorage.setItem('user_id', res.data.data.id);
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('isFresh', res.data.data.isFresh);
          localStorage.setItem('isPaid', res.data.data.isPaid);
          localStorage.setItem('phone', res.data.data.phone);

        if(msg === 'PAYMENT_PENDING')
        {
          localStorage.setItem('payment_status', 'pending');
         window.location.href = "/upgrade";
        }
        else
        {
          localStorage.setItem('payment_status', 'completed');
          window.location.href = "/";
        }
      })
      .catch(function (err) {

        th.setState({
          errors: err?.response?.data.errors,
          submitButton: 'Submit Again',
        });
      });
  }

  handleRegisterForm(e) {
    e.preventDefault();
    this.setState({ submitButton: 'Please Wait..' });
    let th = this;
    let data: any = {};
    data.name = e.target.name.value;
    data.email = e.target.email.value;
    data.password = e.target.password.value;
    data.password_confirmation = e.target.password_confirmation.value;
    data.phone = e.target.phone.value;
    data.referral = null //e.target?.referral.value ?? null;
    axios({
      method: 'POST',
      url: this.state.url + 'auth/register',
      data: data
    })
      .then((res) => {
        //this.openModal('registerSuccess', 'register');
        // Adding userdata to localstorage 
        th.props.addUserData({ user: res.data.data });
        localStorage.setItem('name', res.data.data.name);
        localStorage.setItem('phone', res.data.data.phone);
        localStorage.setItem('email', res.data.data.email);
        localStorage.setItem('photoURL', res.data.data.photoUrl);
        localStorage.setItem('class', res.data.data.class);
        localStorage.setItem('user_id', res.data.data.id);
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('isFresh', res.data.data.isFresh);
        localStorage.setItem('isPaid', res.data.data.isPaid);

        //sending user to payment upgradation page
        localStorage.setItem('payment_status', 'pending');
        window.location.href = "/upgrade";

        th.setState({
          submitButton: 'Submitted',
          errors: [],
          success: '',
          data : {
            heading : 'Registration Successfull! ', 
            message : 'We have sent you a verification link to your email, Please check your email and verify to Login'
          }
        });
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        th.setState({
          errors: err.response.data.errors,
          submitButton: 'Submit Again'
        });
      })
  }

  handleEmailVerification() {
    let urlpath = window.location.href;
    if (urlpath.includes('verify-email')) {
      let token = this.props.token;
      token = token.replace('#/verify-email/', '');
      let th = this;
      let data: any = {
        token: token
      };

      axios({
        method: 'POST',
        url: this.state.url + 'auth/verify-email',
        data: data
      })
        .then((res) => {
          console.log(res.data);
          th.setState({
            data: { 'heading': 'Verification Success!!', 'message': 'Your Email id is now verified' }
          });
          this.openModal('verifyEmail', 'null');
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          th.setState({
            data: { 'heading': 'Invalid Request', 'message': 'Please check url' }
          });
          this.openModal('verifyEmail', 'null');
        })
    }

  }

  handleForgotForm(e) {
    e.preventDefault();
    this.setState({ submitButton: 'Please Wait..' });
    let th = this;
    let data: any = {};
    data.email = e.target.email.value;
    axios({
      method: 'POST',
      url: this.state.url + 'auth/forgot-password',
      data: data
    })
      .then((res) => {
        console.log(res.data);
        th.setState({
          submitButton: 'Mail Sent',
          errors: [],
          success: 'Please check your email id to reset the password'
        });
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        th.setState({
          errors: err.response.data.errors,
          submitButton: 'Submit Again'
        });
      })
  }

  openResetForm() {
    let urlpath = window.location.href;
    if (urlpath.includes('reset-password')) {
      let token = this.props.token;
      token = token.replace('#/reset-password/', '');
      this.setState({
        data: { resetToken: token }
      });
      this.openModal('resetFormButton', 'null');
    }
  }

  openRegistrationForm() {
    let urlpath = window.location.href;
    if (urlpath.includes('referral')) {
      let token = this.props.token;
      token = token.replace('#/referral/', '');
      this.setState({
        refer_token: token
      });
      this.openModal('registerButton', 'null');
    }
  }

  handleResetPasswordForm(e) {
    e.preventDefault();
    this.setState({ submitButton: 'Please Wait..' });
    let th = this;
    let data: any = {};
    data.token = e.target.token.value;
    data.password = e.target.password.value;
    data.password_confirmation = e.target.password_confirmation.value;
    axios({
      method: 'POST',
      url: this.state.url + 'auth/reset-password',
      data: data
    })
      .then((res) => {
        //console.log(res.data); 
        th.setState({
          submitButton: 'Success',
          errors: [],
          success: 'Please reset successfull, please login now'
        });
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        th.setState({
          errors: err.response.data.errors,
          submitButton: 'Submit Again'
        });
      })
  }

  formError() {
    let errors = this.state.errors;
    var err_list: any = [];
    Object.values(errors).forEach((value: any) => {
      value && value.length > 0 && value.map((item: any, key: any) =>
        err_list.push(item)
      )
    });
    return (
      <React.Fragment>
        <p className="error">{this.state.data.socialLoginError}</p>
        <ul className="error">
          {
            err_list && err_list.length > 0 && err_list.map((item: any, key: any) =>
              <li key={key}>{item}</li>
            )
          }
        </ul>
      </React.Fragment>
    );

  }

  openModal(open, close) {

    this.setState({
      errors: [],
      submitButton: 'Submit'
    });
    document.getElementById(close)?.click();
    document.getElementById(open)?.click();
  }

  resetErrors() {
    // Reset all errors and button text once close button is clicked 
    this.setState({
      errors: [],
      data: {},
      submitButton: 'Submit'
    });
  }

  componentWillUnmount()
  {
    this.openModal('', 'closeRegister'); 
  }

  render() {
    if (this.state.redirect_to) {
      document.getElementById('closeLogin')?.click();
      return <Redirect to={this.state.redirect_to} />
    }
    return (
      <React.Fragment>

<a href="?#" data-toggle="modal" id="resetButton" data-target="#reset" style={{ display : 'None' }}>Reset</a>
<a href="?#" data-toggle="modal" id="resetFormButton" data-target="#resetForm" style={{ display : 'None' }}>Reset</a>
<a href="?#" data-toggle="modal" id="verifyEmail" data-target="#emailVerify" style={{ display : 'None' }}>Reset</a>
<a href="?#" data-toggle="modal" id="registerSuccess" data-target="#registerSuccessModal" style={{ display : 'None' }}>Register Successfull</a>
<footer>
  <div className="container">
    <div className="row">
      <div className="col-md-12 text-center">
        © 2021. All Rights Reserved by Practiceus
      </div>
    </div>
  </div>
</footer>
        {/* The Login Modal */}
        <div className="modal" id="login">
          <div className="circel-block">
            <div className="form-card">
              <button type="button" className="close" id="closeLogin" onClick={() => this.resetErrors()} data-dismiss="modal">Close</button>
              <div className="ttl">Login</div>
              <p className="txt">If you are an existing customer, please login to continue</p>
              
              <form onSubmit={(e) => this.handleLoginForm(e)} className="forms">
              <div className="form-group">
                {this.formError()}
              </div>
                <div className="form-group">
                  <input type="text" name="email" placeholder="Email" className="form-control" required />
                </div>
                <div className="form-group">
                  <input type="password" name="password" placeholder="Password" className="form-control" required />
                </div>
                <div className="form-group remeberme">
                  <input type="checkbox" name="rememberme"/> <label> Remember me</label>
                </div>
                <div className="form-group">
                  <input type="submit" value={this.state.submitButton}  className="form-control" />
                  <label className="forgot">Forgot Password? <a href="#?" onClick={() => this.openModal('resetButton', 'closeLogin')}>Reset</a></label>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* The Forgot password modal */}
        <div className="modal" id="reset">
          <div className="circel-block">
          <div className="form-card">
            <button type="button" className="close" id="closeReset" onClick={() => this.resetErrors()} data-dismiss="modal">Close</button>
            <div className="ttl">Forgot Password?</div>
            <p className="txt">If you forgot your password, you can reset it from here</p>
            <span className="success">{this.state.success}</span>
           
            <form onSubmit={(e) => this.handleForgotForm(e)} className="forms">
            <div className="form-group">
                {this.formError()}
            </div>

              <div className="form-group">
                <input type="text" name="email" placeholder="Email" className="form-control" required />
              </div>
              <div className="form-group">
                <input type="submit" className="form-control" value={this.state.submitButton} />
                <div className="signup">Already have an account? <a href="#?" onClick={() => this.openModal('loginButton', 'closeReset')}>Login</a></div>
              </div>
            </form>
          </div>
          </div>
        </div>

        {/* The registeration Modal */}
        <div className="modal" id="register">
          <div className="circel-block">
          <div className="form-card">
            <button type="button" className="close" onClick={() => this.resetErrors()} id="closeRegister" data-dismiss="modal">Close</button>
            <div className="ttl">Register </div>
            <p className="txt"> Read anywhere.Practice with Practiceus</p>
            <span className="success">{this.state.success}</span>
            
            <form onSubmit={(e) => this.handleRegisterForm(e)} className="forms">
            <div className="form-group">
                {this.formError()}
            </div>
              <div className="form-group">
                <input name="email" type="text" placeholder="Email" className="form-control" required />
              </div>
              <div className="form-group">
                <input name="name" type="text" placeholder="Name" className="form-control" required />
              </div>
              <div className="form-group">
                <input type="text" min="1" max="9999999999" pattern="[0-9]+" title="Phone Number Must be of 10 digit" name="phone" placeholder="Phone" className="form-control" required  />
              </div>
              <div className="form-group">
                <input type="password" name="password" placeholder="Password" className="form-control" required />
              </div>
              <div className="form-group">
                <input type="password" name="password_confirmation" placeholder="Password Confirmation" className="form-control" required />
                <input type="hidden" name="class" value="12" />
              </div>

             {/*  <div className="form-group">
                {
                  (this.state.refer_token === '') ? 
                  <input type="text" name="referral" placeholder="Referral code" className="form-control"/>
                  :
                  <input type="text" name="referral" placeholder="Referral code" className="form-control" value={this.state.refer_token}/>
                }
                
                <input type="hidden" name="class" value="12" />
              </div> */}
              <div className="form-group">
                <input type="checkbox" required />
                <p><Link to="/terms">Agree to Terms and condition</Link></p>
              </div>
              <div className="form-group">
                <input type="submit" className="form-control" value={this.state.submitButton}/>
              </div>
            </form>
          </div>
          </div>
        </div>

        {/* Verify email */}
        <div className="modal" id="emailVerify">
          <div className="circel-block">
          <div className="form-card">
            <button type="button" className="close" onClick={() => this.resetErrors()} id="closeEmailVerification" data-dismiss="modal">Close</button>
            <div className="ttl">{this.state.data.heading}</div>
            <p className="txt">{this.state.data.message}</p>
            <div className="signup text-center"> <a href="#?" className="verfication" onClick={(e) => this.openModal('loginButton', 'closeEmailVerification')}>Login Now</a></div>
          </div>
          </div>
        </div>
        
         {/* Register Success */}
         <div className="modal" id="registerSuccessModal">
          <div className="circel-block">
          <div className="form-card">
            <button type="button" className="close" onClick={() => this.resetErrors()} id="closeRegisterSuccess" data-dismiss="modal">Close</button>
            <div className="ttl">{this.state.data.heading}</div>
            <p className="txt">{this.state.data.message}</p>
            <div className="signup text-center"> <a href="#?" className="verfication" onClick={(e) => this.openModal('loginButton', 'closeRegisterSuccess')}>Login Now</a></div>
          </div>
          </div>
        </div>

        {/* Modal for Reseting Password Form */}
        <div className="modal" id="resetForm">
          <div className="circel-block">
          <div className="form-card">
            <button type="button" className="close" onClick={() => this.resetErrors()} id="closeResetForm" data-dismiss="modal">Close</button>
            <div className="ttl">Reset Form</div>
            <p className="txt">If you are an existing customer, please login to continue</p>
            
            <form onSubmit={(e) => this.handleResetPasswordForm(e)} className="forms">
            <div className="form-group">
                {this.formError()}
            </div>
              <div className="form-group">
                <input type="hidden" name="token" value={this.state.data.resetToken} />
                <input type="password" name="password" placeholder="Password" className="form-control" required />
              </div>
              <div className="form-group">
                <input type="password" name="password_confirmation" className="form-control" placeholder="Password Confirmation" />
              </div>

              <div className="form-group">
                <input type="submit" className="form-control" value={this.state.submitButton} />
                <div className="signup"><a href="#?" onClick={(e) => this.openModal('loginButton', 'closeResetForm')}>Login Now</a></div>
              </div>
            </form>
          </div>
          </div>
        </div>

      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUserData: (data) => { dispatch({ type: 'NEW', payload: data }) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
