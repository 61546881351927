import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Approute from './Approute';
import Header from './template/Header';
// import Nav from './template/Nav';
import Footer from './template/Footer';
// import Banner from './template/Banner';
export default class Common extends Component<any,any> {
    render() {
      let user_id  = localStorage.getItem('user_id');
      if(user_id === null)
      {
          return (
            <React.Fragment>
            <Router>
            <Approute/>
            </Router>
            </React.Fragment>
          )
      }

        return (
            <React.Fragment>
              <Router>
              <Header/>
                    <Approute/>
              <Footer/>
              </Router>
            </React.Fragment>
        )
    }

    
}
