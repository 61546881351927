import React, { Component } from 'react'
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Axios from 'axios';
import Loading from '../template/Loading';
import {Redirect, Link} from 'react-router-dom';
 class SubjectTestQuizList extends Component<any,any> {

    constructor(props)
    {
        super(props); 
        this.state  = {
            data : {}, 
            url : process.env.REACT_APP_API_URL,
            user : this.props.user,
            redirect : null,
            isLoading : true 
        }
    }

    componentDidMount()
    {
      window.scrollTo(0, 0);
      this.getAPIData();
      console.log(this.props.data);
    }

    getAPIData(page=1)
    {
      let slug = this.props.match.params.slug;   
      let th = this;
     // if(!this.checkPreloadedAPI(slug))
     // {
        Axios({
          method : 'POST', 
          data : { slug : slug }, 
          url  : this.state.url+'dashboard/subject-quiz-list?page='+page, 
          headers:{
            Authorization : 'Bearer '+this.state.user.token,
            Accept : 'application/json'
          }
        })
        .then((res)=>{

          let payment_status = res.data.payment_status; 
          if(payment_status === 'PAYMENT_PENDING')
          {
            th.setState({
              redirect : '/logout'
            });
            return false; 
          }
          
          th.setState({
            isLoading:false, 
            data : {
              apiData : res.data, 
              slug : slug
            }
          });
          th.saveAPI(slug,res.data);
        })
        .catch((err)=>{
            console.log(err.response);
          if(err.response?.data.message === 'Unauthenticated.')
          {
            th.setState({
              redirect : '/logout'
            });
          }
        })
     //}
      
    }

    saveAPI(slug,res)
    {
        let list = this.props.data.subjectTestQuizListAPI; 
        if(list === undefined)
        {
            let arr = [{
                slug : slug, 
                api : res
            }];
    
            this.props.updateData({
                subjectTestQuizListAPI : arr
            });
        }
        else
        {
            let obj = {
                slug : slug, 
                api : res
            }
            let arr = [] as any; 
            for(let value of list)
            {
                arr.push(value)
            }
            arr.push(obj);
            this.props.updateData({
                subjectTestQuizListAPI : arr
            });
        }
      
    }

    checkPreloadedAPI(slug)
    {
        let list = this.props.data.subjectTestQuizListAPI; 
        if(list === undefined)
        {
            return false;
        }
        else
        {
            for(let value of list)
            {
                if(value.slug === slug)
                {
                    this.setState({
                        isLoading:false, 
                        data : {
                          apiData : value.api, 
                          slug : slug
                        }
                      });
                    return true; 
                }
            }
            return false; 
        }
        
    }

    nextPage(e,page)
    {
      e.preventDefault();
      this.setState({
        isLoading : true
      })
      this.getAPIData(page);
      window.scrollTo(0, 0);
    }

    prevPage(e,page)
    {
      e.preventDefault();
      this.setState({
        isLoading : true
      })
      this.getAPIData(page);
      window.scrollTo(0, 0);
    }

    render() {
      if (this.state.redirect) {
        return <Redirect to={this.state.redirect} />
      }
        if (this.state.isLoading === true) return <Loading/>
        let data = this.state.data.apiData.data; 
        let api = this.state.data.apiData; 
        return (
            <React.Fragment>
                  <Helmet>
        <title> Subject Test Quiz</title>
              </Helmet>
    <section>
  <div className="container wraper">
    <div className="row">
      <div className="col-md-12">
        <Link to="/subjects"><i className="fa fa-angle-left back-btn" /></Link>
        <div className="qz title">Choose Test </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="table-wrap-big">
          <div className="table">
            {/* Row 1 */}
            {
              data && data.length > 0 && data.map((value,key)=>
              <div className="table-row" key={key}>
              <div className="table-cell">
            <div className="ttl">{value.name}</div>
              </div>
              <div className="table-cell">
                {
                (value.isFresh === 'yes')? 'FIRST SCORE N/A' : 
                <span className="score"> FIRST SCORE <i> {value.first_test_score} </i></span>
                }
              </div>
              <div className="table-cell">
              {
              (value.isFresh === 'yes')? 'LAST SCORE N/A' : 
              <span className="score"> LAST SCORE <i className="yellow">{value.last_test_score}</i></span> 
              }
              </div>
              <div className="table-cell">
              <Link to={"/analysis/"+value.slug}>ANALYSIS</Link>
              </div>
              
              <div className="table-cell text-right">
              <Link to={"/start-test/"+value.slug} className="start-btn">{(value.isFresh === 'yes') ? 'START' : 'RETAKE'}</Link>
              </div>
            </div>

              )
            }
            {
                (data.length === 0) ? 
                <div className="table-row">
                <div className="table-cell">
                  <div className="ttl">No Test Found</div>
                </div>
              </div>
                : ''
            }
        
          </div>
          {
     (api.prev_page_url !== null) ? <a href="?#" className="start-btn" onClick={(e)=>this.prevPage(e,api.current_page - 1)}> Prev </a> : ''
   }     

   {
     (api.next_page_url !== null) ? <a href="?#" className="start-btn" style={{ float : 'right' }} onClick={(e)=>this.nextPage(e,api.current_page + 1)}> Next </a> : ''
   }   
        </div>
      </div>
    </div>
  </div></section>

            </React.Fragment>
        )  
    }
}



const mapStateToProps = (state) => {
    return {
      user : state.user,
      data : state.data
    }  
  }
  
  const mapDispatchToProps = (dispatch) =>{
      return { 
        updateData : (data)=>{dispatch({type:'APPEND', payload : data })}
      }
  }

export default connect(mapStateToProps,mapDispatchToProps)(SubjectTestQuizList);
