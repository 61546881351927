import React, { Component } from 'react'
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Axios from 'axios';
import Loading from '../template/Loading';
import {Redirect, Link} from 'react-router-dom';
import Filter from './Quiz/Filter';
import QuickLinks from './Quiz/QuickLinks';
import  ReactHtmlParser from 'react-html-parser';
import Timer from 'react-compound-timer';
import Error from '../template/Error';

 class Overview extends Component<any,any> {

    constructor(props)
    {
        super(props); 
        this.state  = {
            data : {}, 
            url : process.env.REACT_APP_API_URL,
            user : this.props.user,
            redirect : null,
            isLoading : true,
            timer : {}, 
            error : false, 
            error_data : {}
        }
    }

    componentDidMount()
    {
      //this.startFullScreenMode();
      this.checkPropValue();
      window.scrollTo(0, 0);
      this.checkPaymentStatus();
      this.getAPIData();
    }

    startFullScreenMode()
    {
      let element = document.querySelector("body");
            element?.requestFullscreen()
      .then(function() {
        // element has entered fullscreen mode successfully
      })
      .catch(function(error) {
        // element could not enter fullscreen mode
        // error message
        console.log(error.message);
      });
    }

    checkPropValue()
    {
      if(Object.keys(this.props.data).length === 0)
      {
        this.setState({
          error : true, 
          error_data : {
            title : 'Quiz Page is Refreshed', 
            message : "It seems that you have refreshed the page, Please start the quiz from begining"
          }
        });
      }
    }

    checkPaymentStatus()
    {
      let th = this; 
      Axios({
        method : 'GET', 
        data : {}, 
        url  : this.state.url+'users/payment-status', 
        headers:{
          Authorization : 'Bearer '+localStorage.getItem('token'),
          Accept : 'application/json'
        }
      })
      .then((res)=>{
        let payment_status = res.data.payment_status; 
        if(payment_status === 'PAYMENT_PENDING')
        {
          th.setState({
            redirect : '/logout'
          });
          return false; 
        }
      })
      .catch((err)=>{
        if(err.response.data.message === 'Unauthenticated.')
        {
          th.setState({
            redirect : '/logout'
          });
        }
      });
    }

    componentWillUnmount()
    {
      let startTime = this.state.timer.start_time; 
      var date = new Date();
      var end_time = date.getTime();
      let difference  = end_time - startTime;
      if(this.state.timer.direction === 'backward')
      { 
        difference = this.state.timer.starts - difference; //if data is in backward direction get the start time from api and substract the difference of start and end time
      }

      this.props.updateData({
        timer: {
          direction : this.state.timer.direction,
          startAt : difference, 
          start_time : this.state.timer.start_time
        }, 
        total_questions : this.state.data?.apiData.data.length, 
        current_quiz : this.state.data?.apiData,
        all_questions : this.state.data?.apiData.data
      });
    }




    getAPIData()
    {
      let th = this; 
      let slug = this.props.match.params.slug;
     
        Axios({
          method : 'POST', 
          data : {
              slug : slug
          }, 
          url  : this.state.url+'quiz/overview', 
          headers:{
            Authorization : 'Bearer '+this.state.user.token,
            Accept : 'application/json'
          }
        })
        .then((res)=>{
         
          let direction; 
          let starts;
          if(res.data.quiz.timer_in_ms === '' || res.data.quiz.timer_in_ms == null || res.data.quiz.timer_in_ms === 0)
          {
            direction = 'forward';
            starts = 0;
          }
          else
          {
            direction = 'backward';
            starts =  res.data.quiz.timer_in_ms;
          }

          let date = new Date();
          let start_time = date.getTime(); //the time when page is loaded and quiz started
          th.setState({
            isLoading:false, 
            data : {
              apiData : res.data, 
              slug : slug
            },
            timer: {
              starts : starts,
              direction : direction,
              start_time : start_time
            }
          });
        
        })
        .catch((err)=>{
          if(err.response?.data?.message === 'Unauthenticated.')
          {
            th.setState({
              redirect : '/logout'
            });
          }
          console.log(err.response?.data);
        })
     
    }

    onTimeUp()
    {
        // this.setState({
        //   error : true, 
        //   error_data : {
        //     title : 'Your time is up', 
        //     message : "It seems that you have not attempted any of the questions and your time is up"
        //   }
        // });
        this.setState({
          isLoading : true
        });
        let quiz_id = this.state.data.apiData.quiz.id; 
        let data = {
          quiz_id : this.state.data.apiData.quiz.id, 
          duration : this.state.data.apiData.quiz.timer_in_ms, 
          questions : [], 
          filters : [], 
          type : 'backward'
        }
        let th = this; 
        Axios({
          method : 'POST', 
          data : data,
          url  : this.state.url+'quiz/submit', 
          headers:{
            Authorization : 'Bearer '+this.state.user.token,
            Accept : 'application/json'
          }
        })
        .then((res)=>{
          th.props.updateData({ resultAPI: res.data.data, quizSubmissionStatus : true, submittedQuizId : quiz_id });
          th.setState({
            isLoading : false,
            redirect : '/test/result'
          });
        });

    }
    

   
    timerCount(starts, direction) {
        return (
            <div>
                <Timer initialTime={starts}
                 direction={direction}
                 checkpoints={[
                    {
                        time : 1, //time up
                        callback: ()=>this.onTimeUp()
                    }
                ]}
                 >
                
            <Timer.Hours /> hours &nbsp; &nbsp;
            <Timer.Minutes /> minutes&nbsp;&nbsp;
            <Timer.Seconds /> seconds&nbsp;&nbsp;
           
           
                </Timer>
            </div>
        )
    }

    render() {
      if (this.state.isLoading) {
        return <Loading/>
      }

      if (this.state.redirect) {
        return <Redirect to={this.state.redirect} />
      }

      if (this.state.error === true) return <Error error_title={this.state.error_data.title} error_message={this.state.error_data.message} />
        if (this.state.isLoading === true) return <Loading/>
        let data = this.state.data.apiData.data; 
        return (
            <React.Fragment>
                  <Helmet>
                <title>{ this.state.data.apiData.quiz.name + "Quiz Questions Overview - Practiceus"}</title>
              </Helmet>

                
<section>
  <div className="container wraper">
    <div className="row">
      <div className="col-md-3">
        <div className="qz title">{this.state.data.apiData.quiz.name}</div>
      </div>
      <div className="col-md-9">
          <Filter not_visited={data.length} answered={0} not_answered={0} marked_review={0} answered_and_reviewed={0}/>
      </div>
    </div>
    
    <div className="row">
      <div className="col-md-8">
          <div className="row">

          {
          data && data.length>0 && data.map((value,key)=>


          <div className="col-md-4" key={++key}>
          <Link to={"/test/question/"+value.id}>
          <div className="card start-quiz mb30">
          <strong>{key}</strong>
          <div className="questions">
          {ReactHtmlParser(value.name)}
          </div>
          </div>
          </Link>
          </div>
          )
          }


          {
          (data.length===0) ? 
          <div className="col-md-12">
          <div className="card start-quiz mb30">
          <p><strong>No Question</strong></p>
          <div className="questions">
          <p>No Question found in this quiz</p>
          </div>
          </div>
          </div>

          : ''
          }
          </div>
      </div>

      <div className="col-md-4">
            <div className="fixposition">
                  <div className="row bor">
                    <div className="col-md-12">
                      <div className="qz-timer"> 
                      {this.timerCount(this.state.timer.starts,this.state.timer.direction)}
                      </div>
                    </div>
                    <div className="col-md-12">
                    <QuickLinks questions={data}/>
                    </div>
                </div>
            </div>
      </div>
    </div>
  </div>
  </section>


              </React.Fragment>
        )  
    }
}



const mapStateToProps = (state) => {
    return {
      user : state.user,
      data : state.data
    }  
  }
  
  const mapDispatchToProps = (dispatch) =>{
      return { 
        updateData : (data)=>{dispatch({type:'APPEND', payload : data })}
      }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Overview);
