import React, { Component } from 'react'
import Header from './beforeLogin/header';
import Footer from './beforeLogin/footer';


export default class Home extends Component<any, any> {


  render() {
    let token = this.props.location.hash;
    return (
      <React.Fragment>
        <Header />
        <div className="why">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="headline">
                  <div className="ttl">Why</div>
                  <div className="sb-ttl">Practiceus</div>
                </div>
                <div className="enroll desktop">
                  <a href="?#" data-toggle="modal" data-target="#register">Enroll Now</a>
                </div>
              </div>

             
                <div className="col-md-9 desktop">
                  <div className="card">
                    <div className="ttl">Practice</div>
                    <figure>
                      <img src="/practiceus-assets/image/prepare-icon.png" alt="" className="img-fluid" />
                    </figure>
                    <div className="txt">
                    The biggest and most comprehensive collection of practice tests.Full tests , Subject Tests, Chapter Tests
                     </div>
                  </div>
                  <div className="card">
                    <div className="ttl">Analyze</div>
                    <figure>
                      <img src="/practiceus-assets/image/perform-icon.png" alt="" className="img-fluid" />
                    </figure>
                    <div className="txt">
                    Analyze the outcome of your tests and understand areas of improvement
                    </div>
                  </div>
                  <div className="card">
                    <div className="ttl">Improve</div>
                    <figure>
                      <img src="/practiceus-assets/image/progress-icon.png" alt="" className="img-fluid" />
                    </figure>
                    <div className="txt">
                    Go back and improve either a subject, or accuracy and come back and take more tests.
                    </div>
                  </div>
                  <div className="enroll mobile">
                  <a href="?#" data-toggle="modal" data-target="#register">Enroll Now</a>
                  </div>
                </div>
                <div className="col-md-12 mobile">
                  <section className="center slider">
                    <div>
                      <div className="card">
                        <div className="ttl">Prepare</div>
                        <figure>
                          <img src="/practiceus-assets/image/prepare-icon.png" alt="" className="img-fluid" />
                        </figure>
                        <div className="txt">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                         </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <div className="ttl">Perform</div>
                        <figure>
                          <img src="/practiceus-assets/image/perform-icon.png" alt="" className="img-fluid" />
                        </figure>
                        <div className="txt">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <div className="ttl">Progress</div>
                        <figure>
                          <img src="/practiceus-assets/image/progress-icon.png" alt="" className="img-fluid" />
                        </figure>
                        <div className="txt">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                         </div>
                      </div>
                    </div>
                  </section>
                </div>
              


            </div>
          </div>
        </div>
        <div className="boast">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="headline">
                  <div className="ttl">Practiceus boast of</div>
                  <div className="sb-ttl">Hand-curated Content</div>
                </div>
                <p className="static-txt">The Mock-Test series is curated by industry experts and Alumni.</p>
              </div>
              <div className="col-md-6">
                <div className="enroll desktop">
                  <a href="?#" data-toggle="modal" data-target="#register">Enroll Now</a>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-12">
                <section className="center slider">
                  <div>
                    <div className="block">
                      <img alt="" src="/practiceus-assets/image/testimonial-profile.png" className="profile-pic" />
                      <span className="name">Dr. John Doe</span>
                      <div className="text">Lorem ipsum dolor sit amet, consec tetur adipiscing elit</div>
                    </div>
                  </div>
                  <div>
                    <div className="block">
                      <img alt="" src="/practiceus-assets/image/testimonial-profile.png" className="profile-pic" />
                      <span className="name">Dr. John Doe</span>
                      <div className="text">Lorem ipsum dolor sit amet, consec tetur adipiscing elit</div>
                    </div>
                  </div>
                  <div>
                    <div className="block">
                      <img alt="" src="/practiceus-assets/image/testimonial-profile.png" className="profile-pic" />
                      <span className="name">Dr. John Doe</span>
                      <div className="text">Lorem ipsum dolor sit amet, conse ctetur adipiscing elit</div>
                    </div>
                  </div>
                  <div>
                    <div className="block">
                      <img alt="" src="/practiceus-assets/image/testimonial-profile.png" className="profile-pic" />
                      <span className="name">Dr. John Doe</span>
                      <div className="text">Lorem ipsum dolor sit amet, co nsect  etur adipiscing elit</div>
                    </div>
                  </div>
                  <div>
                    <div className="block">
                      <img alt="" src="/practiceus-assets/image/testimonial-profile.png" className="profile-pic" />
                      <span className="name">Dr. John Doe</span>
                      <div className="text">Lorem ipsum dolor sit amet, con sect  etur adipiscing elit</div>
                    </div>
                  </div>
                  <div>
                    <div className="block">
                      <img alt="" src="/practiceus-assets/image/testimonial-profile.png" className="profile-pic" />
                      <span className="name">Dr. John Doe</span>
                      <div className="text">Lorem ipsum dolor sit amet, cons ect etur adipiscing elit</div>
                    </div>
                  </div>
                </section>
                <div className="enroll mobile">
                  <a href="?#" data-toggle="modal" data-target="#register">Enroll Now</a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="keep-your">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="headline">
                  <div className="ttl">Keep your</div>
                  <div className="sb-ttl">Practice Handy</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="enroll desktop">
                  <a href="?#" data-toggle="modal" data-target="#register">Enroll Now</a>
                </div>
              </div>
            </div>
            <div className="row keep-your-section">
              <div className="col-md-6">
                <img src="/practiceus-assets/image/practice-handy-left-img.png" alt="" className="img-fluid desktop" />
              </div>
              <img src="/practiceus-assets/image/practice-handy-right-img.png" alt="" className="img-abs desktop" />
              <img src="/practiceus-assets/image/practice-handy-right-img-mobile.png" alt="" className="img-fluid mobile" />
              <div className="enroll mobile">
                <a href="?#" data-toggle="modal" data-target="#register">Enroll Now</a>
              </div>
            </div>
          </div>
        </div>
        <div className="complete-syllabus">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="headline">
                  <div className="ttl">360° Sphere of</div>
                  <div className="sb-ttl">Complete Syllabus</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="enroll desktop">
                  <a href="?#" data-toggle="modal" data-target="#register">Enroll Now</a>
                </div>
              </div>
            </div>
            <div className="row complete-syllabus-section">
              <div className="col-md-6">
                <img src="/practiceus-assets/image/complete-syllabus.png" alt="" className="img-fluid desktop" />
                <img src="/practiceus-assets/image/complete-syllabus-mobile.png" alt="" className="img-fluid mobile" />
              </div>
              <div className="col-md-6">
                <div className="headline-blk">
                  <p className="head">Practice questions from BITSTAT syllabus</p>
                  <p className="sb-head">Solve high quality BITSAT level questions with academy's question bank</p>
                </div>
                <p className="head">Comprehensive BITSAT question bank</p>
                <p className="sb-head">Cover the full BITSAT syllabus with our curated question bank with 10,000+ questions</p>
                <p className="head">Learn easily with hints</p>
                <p className="sb-head">Use hints to gain confidence in solving difficult BITSAT questions</p>
                <p className="head">Detailed step by step solutions</p>
                <p className="sb-head">In-depth solutions to help you master concepts of Class XI and Class XII</p>
              </div>
            </div>
          </div>
        </div>
    {/*     <div className="good-company">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="headline">
                  <div className="ttl">You are in</div>
                  <div className="sb-ttl">Good Company</div>
                </div>
              </div>
            </div>
            <div className="row good-company-section">
              <div className="col-md-12">
                <div className="block-right">
                  <figure>
                    <img src="/practiceus-assets/image/director-img.png" alt="" />
                  </figure>
                  <div className="name">Asutosh Kumar</div>
                  <div className="des">Director, Auspicious Pvt Ltd</div>
                </div>
                <div className="block-left">
                  <p className="txt">Practiceus made it possible for me to have my virtual books anywhere I want. Being a science lover there’s no more hassle of carrying a load. Extremely handy app with intriguing science section. Two thumbs up!</p>
                  <img src="/practiceus-assets/image/star-rating.png" alt="" />
                </div>
              </div>
              <div className="col-md-12 even">
                <div className="block-right">
                  <figure>
                    <img src="/practiceus-assets/image/director-img.png" alt="" />
                  </figure>
                  <div className="name">Asutosh Kumar</div>
                  <div className="des">Director, Auspicious Pvt Ltd</div>
                </div>
                <div className="block-left">
                  <p className="txt">Practiceus made it possible for me to have my virtual books anywhere I want. Being a science lover there’s no more hassle of carrying a load. Extremely handy app with intriguing science section. Two thumbs up!</p>
                  <img src="/practiceus-assets/image/star-rating.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="what-waiting">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="ttl">What are waiting for?</div>
                <div className="row">
                  <div className="col-md-8">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
                  <div className="col-md-4">
                    <div className="enroll">
                      <a href="?#" data-toggle="modal" data-target="#register">Enroll Now</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="ftr-last">
                  <div className="logos" >
                    <img src="/practiceus-assets/image/logo.png" alt="" className="img-fluid"/>
                  </div>
                  <div className="support">
                    Customer Support
              <a href="?#">info@steamdaily.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <Footer token={token} />
      </React.Fragment>
    )
  }
}
