import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export default class ScriptFile extends Component {
    render() {
        return (
            <React.Fragment>
            <Helmet>
            <link rel="stylesheet" href="/practiceus-assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
      <link rel="stylesheet" href="/practiceus-assets/css/practiceus-style.css?v=1.5.10" />
      {/* SLick Slider */}
      <link rel="stylesheet" type="text/css" href="/practiceus-assets/css/slick/slick.css" />
      <link rel="stylesheet" type="text/css" href="/practiceus-assets/css/slick/slick-theme.css" />
      {/* Reveal Js */}
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
            </Helmet>
            </React.Fragment>
        )
    }
}
