const iState = {
        user : {
            name : localStorage.getItem('name'),
            email : localStorage.getItem('email'),
            user_id : localStorage.getItem('user_id'),
            photoURL : localStorage.getItem('photoURL'),
            class : localStorage.getItem('class'),
            token : localStorage.getItem('token'),
            isFresh : localStorage.getItem('isFresh')
        }, 
        data : {}
}
const reducer = (state=iState, action)=>{
   switch (action.type) {
       case 'APPEND':
           return {
               ...state, 
               data : Object.assign(state.data, action.payload)
           }
        case 'NEW':
            return {
                ...state, 
                data: action.payload
            }
            
       default:
           return state;
   }
}

export default reducer; 