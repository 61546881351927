import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './Home';
import Dashboard from './Dashboard';
// import Content from './Content';
import Contact from './Contact';
import About from './About';
import Logout from './Logout';
import Fulltest from './dashboard/Fulltest';
import Subjecttest from './dashboard/Subjecttest';
import Chaptertest from './dashboard/Chaptertest';
import SubjectTestQuizList from './dashboard/SubjectTestQuizList';
import ChapterTestQuizList from './dashboard/ChapterTestQuizList';
import StartQuiz from './dashboard/StartQuiz';
import Overview from './dashboard/Overview';
import Question from './dashboard/Question';
import Result from './dashboard/Result';
import ChangePassword from './dashboard/profile/ChangePassword';
import Updateprofile from './dashboard/profile/Updateprofile';
import payment_page from './dashboard/payment_page';
import Refer from  './dashboard/Refer';
import Analysis from  './dashboard/Analysis';
import Terms from './Terms';
import HowItWork from './HowItWork';
import HowItWorksInternal from './dashboard/HowItWorksInternal';
import PreviousYearTest from './dashboard/PreviousYearTest';
export default class Approute extends Component {
    
   

    render() {
        let user_id  = localStorage.getItem('user_id');
        if(user_id === null)
        {
            return (
                  <Switch>
                      {this.homeRoutes()}
                  </Switch>  
            )
        }

        return (
            <div>
                <Switch>
                    {this.dashboardRoutes()}
                </Switch>
            </div>
        )
    }

    dashboardRoutes()
    {
        return (
                <React.Fragment>
                    <Route exact path='/' component={Dashboard} />
                    {/* <Route path='/:slug' component={Content} /> */}
                    <Route path='/previous-year-questions' component={PreviousYearTest} />
                    <Route path='/full-test' component={Fulltest} />
                    <Route path='/subjects' component={Subjecttest} />
                    <Route path='/subject/:slug' component={SubjectTestQuizList} />
                    <Route path='/chapters' component={Chaptertest} />
                    <Route path='/chapter/:slug' component={ChapterTestQuizList} />
                    <Route path='/start-test/:slug' component={StartQuiz} />
                    <Route path='/test/overview/:slug' component={Overview} />
                    <Route path='/test/question/:question_id' component={Question} />
                    <Route path='/test/result' component={Result} />
                    <Route path='/contact' component={Contact} />
                    <Route path='/about' component={About} />
                    <Route path='/logout' component={Logout} />
                    <Route path='/change-password' component={ChangePassword} />
                    <Route path='/update-profile' component={Updateprofile} />
                    <Route path='/refer' component={Refer} />
                    <Route path="/upgrade" component={payment_page} />
                    <Route path="/analysis/:slug" component={Analysis} />
                    <Route path='/how-it-works' component={HowItWorksInternal}/>
                </React.Fragment>
        )
    }

    homeRoutes()
    {
        return (
            <React.Fragment>
                <Route exact path='/' component={Home} />
                <Route path='/terms' component={Terms} />
                <Route path='/how-it-work' component={HowItWork} />
                <Route path='/verify-email/:token' component={Home} />
                <Route path='/reset-password/:token' component={Home} />
            </React.Fragment>
        )
    }
}
