import React, { Component } from 'react'
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Axios from 'axios';
import Loading from '../template/Loading';
import ReactHtmlParser from 'react-html-parser';
import { HorizontalBar, Bar, Line } from 'react-chartjs-2';
class Analysis extends Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      url: process.env.REACT_APP_API_URL,
      user: this.props.user,
      redirect: null,
      isLoading: true
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAPIData();
  }

  scoreGraph(scoreData) {
    let score: any[] = [];
    score.push(0);
    score.push(scoreData.low);
    score.push(scoreData.medium);
    score.push(scoreData.hard);
    let data = {
      barThickness: 0,
      maxBarThickness: 0,
      labels: ['fields', 'Easy', 'Medium', 'Hard'],
      datasets: [
        {
          label: '# of Scores',
          data: score,
          backgroundColor: [
            'rgb(0,128,0)',
            'rgb(0,128,0)',
            'rgb(128,128,128)',
            'rgb(255,0,0)',
          ],
          borderColor: [
            'rgb(0,128,0)',
            'rgb(0,128,0)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
        },
      ],
    }

    let options = {
      legend: {
        display: false
      },
      responsive: true,
      aspectRatio: 1,
      scales: {
        xAxes: [{
          display: false
        }],
        yAxes: [
          {
            display: false,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }

    return <HorizontalBar data={data} options={options} />
  }

  accuracyGraph(accuracyData) {
    let accuracy: any[] = [];
    accuracy.push(0);
    accuracy.push(accuracyData.correct);
    accuracy.push(accuracyData.incorrect);
    accuracy.push(accuracyData.not_answered);
    let data = {
      barThickness: 0,
      maxBarThickness: 0,
      labels: ['fields', 'Correct', 'Incorrect', 'Not Answered'],
      datasets: [
        {
          label: '# of accuracy',
          data: accuracy,
          backgroundColor: [
            'rgb(0,128,0)',
            'rgb(0,128,0)',
            'rgb(255,0,0)',
            'rgb(128,128,128)',
          ],
          borderColor: [
            'rgb(0,128,0)',
            'rgb(0,128,0)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
        },
      ],
    }

    let options = {
      legend: {
        display: false
      },
      responsive: true,
      aspectRatio: 1,
      scales: {
        xAxes: [{
          display: false
        }],
        yAxes: [
          {
            display: false,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }

    return <HorizontalBar data={data} options={options} />
  }

  levelGraphEasy(levelData) {

    let level: any[] = [];
    level.push(levelData.correct);
    level.push(levelData.incorrect);
    level.push(levelData.not_answered);

    let data = {
      barThickness: 1,
      maxBarThickness: 1,
      labels: ['Correct', 'Incorrect', 'Not Answered'],
      datasets: [
        {
          label: '# of Easy',
          data: level,
          backgroundColor: [
            'rgb(0,128,0)',
            'rgb(255,0,0)',
            'rgb(128,128,128)',
          ],
          borderColor: [
            'rgb(0,128,0)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
        },
      ],
    }

    let options = {
      legend: {
        display: false
      },
      responsive: true,
      aspectRatio: 1,
      scales: {
        xAxes: [{
          display: true
        }],
        yAxes: [
          {
            display: true,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }

    return <Bar data={data} options={options} />
  }

  levelGraphMedium(levelData) {
    let level: any[] = [];
    level.push(levelData.correct);
    level.push(levelData.incorrect);
    level.push(levelData.not_answered);
    let data = {
      barThickness: 1,
      maxBarThickness: 1,
      labels: ['Correct', 'Incorrect', 'Not Answered'],
      datasets: [
        {
          label: '# of Medium',
          data: level,
          backgroundColor: [
            'rgb(0,128,0)',
            'rgb(255,0,0)',
            'rgb(128,128,128)',
          ],
          borderColor: [
            'rgb(0,128,0)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
        },
      ],
    }

    let options = {
      legend: {
        display: false
      },
      responsive: true,
      aspectRatio: 1,
      scales: {
        xAxes: [{
          display: true
        }],
        yAxes: [
          {
            display: true,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }

    return <Bar data={data} options={options} />
  }

  levelGraphHard(levelData) {

    let level: any[] = [];
    level.push(levelData.correct);
    level.push(levelData.incorrect);
    level.push(levelData.not_answered);
    let data = {
      barThickness: 1,
      maxBarThickness: 1,
      labels: ['Correct', 'Incorrect', 'Not Answered'],
      datasets: [
        {
          label: '# of Hard',
          data: level,
          backgroundColor: [
            'rgb(0,128,0)',
            'rgb(255,0,0)',
            'rgb(128,128,128)',
          ],
          borderColor: [
            'rgb(0,128,0)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
        },
      ],
    }

    let options = {
      legend: {
        display: false
      },
      responsive: true,
      aspectRatio: 1,
      scales: {
        xAxes: [{
          display: true
        }],
        yAxes: [
          {
            display: true,
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }

    return <Bar data={data} options={options} />
  }

  progressAccuracyChart(accuracyData) {
    let labels: any[] = [];
    let accuracy: any[] = [];
    for (let value of accuracyData) {
      labels.push(value.attempt);
      accuracy.push(value.total_accuracy);
    }


    let data: any = {
      labels: labels,
      datasets: [
        {
          label: 'Accuracy',
          data: accuracy,
          fill: false,
          borderColor: "#bae755",
          backgroundColor: "#e755ba",
          pointBackgroundColor: "#55bae7",
          pointBorderColor: "#55bae7",
          pointHoverBackgroundColor: "#55bae7",
          pointHoverBorderColor: "#55bae7",
        },
      ],
    }

    let options: any = {
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Attempts'
            },
          }
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            scaleLabel: {
              display: true,
              labelString: 'Accuracy'
            }
          },
        ],
      },
    }

    return <Line data={data} options={options} />
  }

  progressScoreChart(scoreData) {
    let labels: any[] = [];
    let score: any[] = [];
    for (let value of scoreData) {
      labels.push(value.attempt);
      score.push(value.total_score);
    }

    let data: any = {
      labels: labels,
      datasets: [
        {
          label: 'Score',
          data: score,
          fill: false,
          borderColor: "yellow",
          backgroundColor: "#e755ba",
          pointBackgroundColor: "#55bae7",
          pointBorderColor: "#55bae7",
          pointHoverBackgroundColor: "#55bae7",
          pointHoverBorderColor: "#55bae7",
        },
      ],
    }

    let options: any = {
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Attempts'
            },
          }
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            scaleLabel: {
              display: true,
              labelString: 'Score'
            }
          },
        ],
      },
    }

    return <Line data={data} options={options} />
  }

  getAPIData(attempt = 'all') {
    let th = this;
    let slug = this.props.match.params.slug;
    Axios({
      method: 'POST',
      url: this.state.url + 'analysis',
      data: {
        quiz_slug: slug,
        attempt: attempt
      },
      headers: {
        Authorization: 'Bearer ' + this.state.user.token,
        Accept: 'application/json'
      }
    })
      .then((res) => {
        th.setState({
          isLoading: false,
          data: {
            apiData: res.data.data,
            slug: slug,
            attempt: attempt
          }
        });
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response?.data.message === 'Unauthenticated.') {
          th.setState({
            redirect: '/logout'
          });
        }
      });
  }


  attemptOptions() {

    let options: any[] = [];
    for (let value of this.state.data?.apiData?.attempts) {
      options.push(value);
    }
    return options;
  }

  handleAttemptDropDown(e) {
    let attempt = e.target.value;
    this.setState({
      isLoading: true
    });
    this.getAPIData(attempt);
  }

  /*
  * Convert ms to h:m:s
  */
   msToTime(duration) {
      let seconds = Math.floor((duration / 1000) % 60);
      let minutes = Math.floor((duration / (1000 * 60)) % 60);
      let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  
    let h = (hours < 10) ? "0" + hours : hours;
    let m = (minutes < 10) ? "0" + minutes : minutes;
    let s = (seconds < 10) ? "0" + seconds : seconds;
  
    return h + ":" + m + ":" + s;
  }

  render() {
    if (this.state.isLoading === true) return <Loading />
    let data = this.state.data.apiData;
    let attempt = this.state.data?.attempt;
    let options = this.attemptOptions();
    let sections_arr = Object.keys(data.sections);
    let sections = data.sections; 
    let reports = data.quiz_report; 
    let report; 
    if(reports.length > 0)
    {
        report = reports[0];
    }
    else
    {
        report = false; 
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>Analysis - Practiceus</title>
        </Helmet>
        <section className="analatics">
          <div className="container">
            <div className="row">
              <div className="col-md-8 p-left0">
                <div className="top-card">
                  <select name="attempts" value={attempt} id="attempt" onChange={(e) => this.handleAttemptDropDown(e)}>
                    {
                      options.length > 0 && options.map((value, key) =>
                        <option key={key} value={value}> Attempt {value}  </option>
                      )
                    }
                    <option value="all">Total Performance</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4 p-right0">
                <div className="top-card right">
                  <div className="healine">Total Test Attempts <div className="hd-number">{data?.number_of_attempts}</div></div>
                </div>
              </div>
            </div>
          </div>
          {/* Container Wraper Start*/}
          <div className="container wraper">
            <div className="row">
              <div className="col-md-7">
                <div className="title">Overall</div>
              </div>
              <div className="col-md-5">
                <div className="card p10">
                  <div className="healine">
                    Correct Answers
                    <div className="hd-number">{data?.overall_data.correct_answer}/{data?.overall_data.total_questions}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card p0">
                  <div className="hd1">Score  <div className="hd-number">{data?.overall_data.score.total}</div></div>
                  <div className="row">
                    <div className="col-md-8 graph">
                      {this.scoreGraph(data?.overall_data.score)}
                    </div>
                    <div className="col-md-4">
                      Total Score {data?.overall_data.score.total}
                    </div>
                    <div className="col-md-12">
                      <table className="analysis-table">
                        <tbody>
                        <tr>
                          <td><span className="green-dot"></span> Easy</td>
                          <td>{data?.overall_data.score.low}</td>
                        </tr>
                        <tr>
                          <td><span className="grey-dot"></span> Medium</td>
                          <td>{data?.overall_data.score.medium}</td>
                        </tr>
                        <tr>
                          <td><span className="red-dot"> </span>Hard</td>
                          <td>{data?.overall_data.score.hard}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-md-6">
                <div className="card p0">
                  <div className="hd1">Avg Accuracy <div className="hd-number">{data?.overall_data.accuracy.accuracy}</div></div>
                  <div className="row">
                    <div className="col-md-8 graph">
                      {this.accuracyGraph(data?.overall_data.accuracy)}
                    </div>
                    <div className="col-md-4">
                      Total Question  {data?.overall_data.accuracy.total_question}
                    </div>
                    <div className="col-md-12">

                      <table className="analysis-table">
                        <tbody>
                        <tr>
                          <td><span className="green-dot"></span> Correct</td>
                          <td>{data?.overall_data.accuracy.correct}</td>
                        </tr>
                        <tr>
                          <td><span className="red-dot"></span> Incorrect</td>
                          <td>{data?.overall_data.accuracy.incorrect}</td>
                        </tr>
                        <tr>
                          <td><span className="grey-dot"> </span>Not Answered</td>
                          <td>{data?.overall_data.accuracy.not_answered}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Conatiner Wraper End */}

          {/* Difficulty level Container Wraper Start*/}
          <div className="container wraper">
            <div className="row">
              <div className="col-md-12">
                <div className="title">Difficulty Levels</div>

              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card p0">
                  <div className="hd1">Easy <div className="hd-number">{data?.difficulty_levels.easy.accuracy}</div></div>
                  <div className="row">
                    <div className="col-md-12 text-right">
                      Total Questions {data?.difficulty_levels.easy.total_question}&nbsp;&nbsp;
                    </div>

                    <div className="col-md-12">
                      {this.levelGraphEasy(data?.difficulty_levels.easy)}
                    </div>


                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card p0">
                  <div className="hd1"> Medium <div className="hd-number">{data?.difficulty_levels.medium.accuracy}</div></div>
                  <div className="row">
                    <div className="col-md-12 text-right">
                      Total Questions {data?.difficulty_levels.medium.total_question}&nbsp;&nbsp;
                    </div>
                    <div className="col-md-12">
                      {this.levelGraphMedium(data?.difficulty_levels.medium)}
                    </div>


                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card p0">
                  <div className="hd1">Hard <div className="hd-number">{data?.difficulty_levels.hard.accuracy}</div></div>
                  <div className="row">
                    <div className="col-md-12 text-right">
                      Total Questions {data?.difficulty_levels.hard.total_question} &nbsp;&nbsp;
                    </div>

                    <div className="col-md-12">
                      {this.levelGraphHard(data?.difficulty_levels.hard)}
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Difficulty level Conatiner Wraper End */}


          {/* Progress Bar Container Wraper Start*/}
          {/* this will be visible only if attempt count is all  */}
          {
            (this.state.data?.attempt === 'all') ?
              <div className="container wraper">
                <div className="row">
                  <div className="col-md-12">
                    <div className="title">Progress</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card p0">
                      {this.progressAccuracyChart(data?.progress.accuracy)}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card p0">
                      {this.progressScoreChart(data?.progress.score)}
                    </div>
                  </div>
                </div>
              </div>
              : ''
          }
          {/* Progress Bar Conatiner Wraper End */}




          {/* Answer Key  Container Wraper Start*/}
          {/* this will be visible only if attempt count is not all  */}
          {
            (this.state.data?.attempt !== 'all' && report !== false) ?
              <div className="container wraper">

                <div className="row">
                  <div className="col-md-6">
                    <div className="title"> Answer Key </div>
                  </div>
                  {
                    (sections.length > 0) ?
                      <React.Fragment>
                        <div className="col-md-6">
                          <div className="title"> Subject Score </div>
                        </div>
                        <div className="col-md-6">
                          <ul className="answerkey-denote">
                            {
                              sections.length > 0 && sections.map((value, key) => (

                                <li key={key}>
                                  <strong>{data.sections[sections[key]]['score']} / {data.sections[sections[key]]['total']} </strong>
                                  <div className="dot-div">
                                    {value} {/* <span className="grey-dot"></span>   */}
                                  </div>
                                </li>

                              ))
                            }
                          </ul>
                        </div>
                      </React.Fragment>
                      : ''
                  }
                </div>
                <div className="row">            
                </div>
                <div className="row">
                <div className="col-md-8 bor-rt">
                  {
                    data.answer_keys.length > 0 && data.answer_keys.map((value, key) =>
                      <div className="repeat-result" key={key}>
                        <div className={(value.status === 'correct') ? 'card start-quiz mb30 border-green' : ((value.status === 'incorrect') ? 'card start-quiz mb30 border-red' : 'card start-quiz mb30 border-grey')}>
                          <strong></strong>
                          <div className="card mb0 pb50">
                            Question : {ReactHtmlParser(value.name)}
                            <div className="ans">
                              <ul>
                                <li className={(value.correctans === 1) ? 'active' : (value.user_answer === 1) ? 'wrong' : ''}>
                                  <strong>A</strong>{ReactHtmlParser(value.option1)}
                                </li>
                                <li className={(value.correctans === 2) ? 'active' : (value.user_answer === 2) ? 'wrong' : ''}>
                                  <strong>B</strong>{ReactHtmlParser(value.option2)}
                                </li>
                                <li className={(value.correctans === 3) ? 'active' : (value.user_answer === 3) ? 'wrong' : ''}>
                                  <strong>C</strong>{ReactHtmlParser(value.option3)}
                                </li>
                                <li className={(value.correctans === 4) ? 'active' : (value.user_answer === 4) ? 'wrong' : ''}>
                                  <strong>D</strong>{ReactHtmlParser(value.option4)}
                                </li>
                              </ul>
                            </div>
                            <div className="result-stick">
                              <span>Your Answer: {value.user_answer}</span>
                              <span>Correct Answer: <i>{value.correctans}</i></span>
                            </div>
                        </div>
                        <div className="explanation">
                              <div className="title">Explanation</div>
                                {ReactHtmlParser(value.explantions)}
                              </div>
                            </div>
                        </div>

                    )
                  }
                  </div>
                  <div className="col-md-4">
                  <div className="result-stats">
                  <ul className="score-blk">
                    <li>
                      <div className="ttl">Your Score</div>
                      <div className="score blue">{report.total_score_earned}/{report.total_score}</div>
                    </li>
                    <li>
                      <div className="ttl">Attempted <span>Question</span></div>
                      <div className="score drk-blue">{report.total_attempted_question}/{report.total_question}</div>
                    </li>
                    <li>
                      <div className="ttl">Correctly <span>Answered</span></div>
                      <div className="score drk-blue">{report.total_correct_ans}/{report.total_question}</div>
                    </li>
                    <li>
                      <div className="ttl">Duration</div>
                      <div className="score orange">{this.msToTime(report.duration)}</div>
                    </li>
                    <li>
                      <div className="ttl">Negative <span>Marks</span></div>
                      <div className="score">{report.negative_mark}</div>
                    </li>

                    {
                      sections_arr.length > 0 && sections_arr.map((value,key)=>(
                        <li key={key}>
                        <div className="ttl">{value}</div>
                        <div >
                        <div className="score blue" style={{fontSize : '11px' }}>Score : {sections[sections_arr[key]]['score']} /  {sections[sections_arr[key]]['total']} </div>
                        <div style={{fontSize : '11px' }} className="score grn" >Correct : {sections[sections_arr[key]]['correct']} /  {sections[sections_arr[key]]['count']} </div>
                        <div style={{fontSize : '11px' }} className="score">Incorrect : {sections[sections_arr[key]]['incorrect']} /  {sections[sections_arr[key]]['count']} </div>
                        <div style={{fontSize : '11px' }} className="score drk-blue">Not Attempted : {sections[sections_arr[key]]['not_attempted']} /  {sections[sections_arr[key]]['count']} </div>
                        </div>
                      </li>
                      ))
                    }
                  </ul>
                </div>
                </div>
                </div>
              </div>
              : ''
          }

          {/* Answer Key Bar Conatiner Wraper End */}
        </section>

      </React.Fragment>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    data: state.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateData: (data) => { dispatch({ type: 'APPEND', payload: data }) }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Analysis);
