import React, { Component } from 'react'
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import Axios from 'axios';
import Loading from '../template/Loading';
import {Redirect, Link} from 'react-router-dom';
import  ReactHtmlParser from 'react-html-parser';
 class StartQuiz extends Component<any,any> {

    constructor(props)
    {
        super(props); 
        this.state  = {
            data : {}, 
            url : process.env.REACT_APP_API_URL,
            user : this.props.user,
            redirect : null,
            isLoading : true      
        }
    }

    componentDidMount()
    {
      window.scrollTo(0, 0);
      this.resetPropsData();
      this.checkPaymentStatus();
      this.getAPIData();
    }

    resetPropsData()
    {
      this.props.updateData({ quizSubmissionStatus : false, submittedQuizId : null });
    }

    checkPaymentStatus()
    {
      let th = this; 
      Axios({
        method : 'GET', 
        data : {}, 
        url  : this.state.url+'users/payment-status', 
        headers:{
          Authorization : 'Bearer '+localStorage.getItem('token'),
          Accept : 'application/json'
        }
      })
      .then((res)=>{
        let payment_status = res.data.payment_status; 
        if(payment_status === 'PAYMENT_PENDING')
        {
          th.setState({
            redirect : '/logout'
          });
          return false; 
        }
      })
      .catch((err)=>{
        if(err.response.data.message === 'Unauthenticated.')
        {
          th.setState({
            redirect : '/logout'
          });
        }
      });
    }
    getAPIData()
    {
      let th = this; 
      let slug = this.props.match.params.slug;
      if(!this.checkPreloadedAPI(slug))
      {
        Axios({
          method : 'POST', 
          data : {
              slug : slug
          }, 
          url  : this.state.url+'quiz/start-quiz', 
          headers:{
            Authorization : 'Bearer '+this.state.user.token,
            Accept : 'application/json'
          }
        })
        .then((res)=>{
          th.setState({
            isLoading:false, 
            data : {
              apiData : res.data,
              slug : slug
            }
          });
         
        th.saveAPI(slug, res.data);
          
        })
        .catch((err)=>{
          if(err.response.data.message === 'Unauthenticated.')
          {
            th.setState({
              redirect : '/logout'
            });
          }
          console.log(err.response.data);
        })
     }
    }

    saveAPI(slug,res)
    {
        let list = this.props.data.startQuizAPI; 
        if(list === undefined)
        {
            let arr = [{
                slug : slug, 
                api : res
            }];
    
            this.props.updateData({
                startQuizAPI : arr
            });
        }
        else
        {
            let obj = {
                slug : slug, 
                api : res
            }
            let arr = [] as any; 
            for(let value of list)
            {
                arr.push(value)
            }
            arr.push(obj);
            this.props.updateData({
                startQuizAPI : arr
            });
        }
      
    }

    checkPreloadedAPI(slug)
    {
        let list = this.props.data.startQuizAPI; 
        if(list === undefined)
        {
            return false;
        }
        else
        {
            for(let value of list)
            {
                if(value.slug === slug)
                {
                    this.setState({
                        isLoading:false, 
                        data : {
                          apiData : value.api, 
                          slug : slug
                        }
                      });
                    return true; 
                }
            }
            return false; 
        }
        
    }

    defaultInstructions()
    {
        return (
          <React.Fragment>
  <div>
  <p><strong>A.</strong> Total duration of ex amination Is 10 minutes.</p>
  <p><strong>B.</strong> The clock will be set at the server. The countdown times in the top corner of screen will display the remaining time available for you to complete the examination. When the timer reaches zero, the examination will end by itself. You will Not be required to end or submit your examination.</p>
  <p><strong>C.</strong> The Question Palette displayed on the right side of screen will show the status of each question using of the following symbols:</p>
  <ul>
    <li>You have answered the question.</li>
    <li>You have not answered the question.</li>
    <li>You have not visited the question yet.</li>
    <li>You have NOT answered the question, but have marked the question for review</li>
    <li>You have answered the question, but marked it to review.</li>
  </ul>
  </div>
          </React.Fragment>
        );
    }

    defaultHints()
    {
      return (
        <span>
          <p><strong>D.</strong> The Marked for Review status for a question simply indicates that you would like to look at that question again. <span className="red"> If a question is answered and Marked for Review, your answer for that question will be considered in the evaluation.</span></p>
          <table className="table-width">
            <tbody>
              {/* <tr> */}
                {/* <th>Test</th> */}
                {/* <th>Algebra Test</th> */}
                {/* <th>Duration</th> */}
                {/* <th>10 Minutes</th> */}
              {/* </tr> */}
              <tr>
                <td colSpan={4} className="orange"><i><img src="https://quizadmin.steamdaily.com/dashboaed-assets/img/donot-refresh.png" alt="" /></i>Do NOT refresh the page</td>
              </tr>
            </tbody>
            </table>
            </span>
      );
    }


    render() {
      if (this.state.redirect) {
        return <Redirect to={this.state.redirect} />
      }
        if (this.state.isLoading === true) return <Loading/>
        let data = this.state.data.apiData.data;  
        return (
            <React.Fragment>
                  <Helmet>
                <title>Start Test - Practiceus</title>
              </Helmet>

            <section>
  <div className="container wraper">
    <div className="row">
      <div className="col-md-12">
        <div className="qz title">Test Instruction</div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="card start-quiz">
          <form>
          { (data[0].instructions !== '') ?  ReactHtmlParser(data[0].instructions) : <this.defaultInstructions/> }
            { (data[0].hints !== '') ?  ReactHtmlParser(data[0].hints) :  <this.defaultHints/> }
            </form>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <Link to={"/test/overview/"+this.state.data.slug} className="start-btn">START TEST NOW</Link>
      </div>
    </div>
  </div>
  </section>


   </React.Fragment>
        )  
    }
}



const mapStateToProps = (state) => {
    return {
      user : state.user,
      data : state.data
    }  
  }
  
  const mapDispatchToProps = (dispatch) =>{
      return { 
        updateData : (data)=>{dispatch({type:'APPEND', payload : data })}
      }
  }

export default connect(mapStateToProps,mapDispatchToProps)(StartQuiz);
